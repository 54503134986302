<template>

  <Line :options="chartOptions" :data="chartData" />

</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartData: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          y: {
            ticks: {
              callback: function(value) {
                return '€' + value;
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += '€' + Number(context.parsed.y).toFixed(2);
                }
                return label;
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    }
  }
}
</script>