<!-- Vendors.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'VM'])">
    <div v-if="findDuplicates.length > 0" class="alert alert-warning p-2 small">
      <i class="bi bi-exclamation-triangle-fill"></i> <strong>There are duplicate vendors:</strong> {{ findDuplicates.join(', ') }}
    </div>

    <VendorsHeader @showAlert="showAlert" @showError="showError" />

    <div class="p-3 shadow-sm bg-white rounded">
      <div v-if="!isFetching('fetchingVendors')">
        <!-- Table filters and search -->
        <div class="filters mb-1 p-2 ps-0 pe-0">
          <div class="d-flex align-items-center">
            <!-- Filters -->
            <div class="d-flex align-items-center">
              <!-- Filter: Type -->
              <div class="dropdown me-2">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="typeDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Type: <strong>{{ selectedType }}</strong>
                </button>
                <div class="dropdown-menu shadow border-0" aria-labelledby="typeDropdownMenuButton">
                  <h6 class="dropdown-header">Filter by Type</h6>
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedType === type }"
                    href="#"
                    v-for="type in uniqueTypes"
                    :key="type"
                    @click.prevent="selectType(type)"
                  >
                    {{ type }}
                  </a>
                </div>
              </div>

              <!-- Filter: Blacklisted? -->
              <div class="dropdown me-2">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="blacklistedDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Blacklisted? <strong>{{ selectedBlacklisted }}</strong>
                </button>
                <div class="dropdown-menu shadow border-0" aria-labelledby="blacklistedDropdownMenuButton">
                  <h6 class="dropdown-header">Filter by Blacklisted?</h6>
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedBlacklisted === status }"
                    href="#"
                    v-for="status in uniqueBlacklistedStatuses"
                    :key="status"
                    @click.prevent="selectBlacklisted(status)"
                  >
                    {{ status }}
                  </a>
                </div>
              </div>

              <!-- Filter: Self Invoicing? -->
              <div class="dropdown me-2">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="selfInvoicingDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Self Invoicing? <strong>{{ selectedSelfInvoicing }}</strong>
                </button>
                <div class="dropdown-menu shadow border-0" aria-labelledby="selfInvoicingDropdownMenuButton">
                  <h6 class="dropdown-header">Filter by Self Invoicing?</h6>
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedSelfInvoicing === status }"
                    href="#"
                    v-for="status in uniqueSelfInvoicingStatuses"
                    :key="status"
                    @click.prevent="selectSelfInvoicing(status)"
                  >
                    {{ status }}
                  </a>
                </div>
              </div>

              <!-- Filter: Added By / Created By -->
              <div class="dropdown">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="addedByDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Created By: <strong>{{ selectedAddedBy }}</strong>
                </button>
                <div class="dropdown-menu shadow border-0" aria-labelledby="addedByDropdownMenuButton">
                  <h6 class="dropdown-header">Filter by Created By</h6>
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedAddedBy === person }"
                    href="#"
                    v-for="person in uniqueAddedBy"
                    :key="person"
                    @click.prevent="selectAddedBy(person)"
                  >
                    {{ person }}
                  </a>
                </div>
              </div>
            </div>

            <!-- Showing text, Pagination and Search -->
            <div class="d-flex align-items-center ms-auto">
              <!-- Showing text -->
              <div class="text-muted small me-3">{{ showingText }}</div>

              <!-- Pagination -->
              <VTPagination
                v-model:currentPage="currentPage"
                :totalPages="totalPages"
                :maxPageLinks="3"
                :boundaryLinks="false"
                class="me-2"
              >
                <template #next>
                  <span class="prevent-select">Next</span>
                </template>

                <template #previous>
                  <span class="prevent-select">Previous</span>
                </template>
              </VTPagination>

              <!-- Search -->
              <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Name, Email, Language Pairs or Native Languages" style="width: 450px;" />
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" @stateChanged="handleStateChanged" :filters="filters" sortHeaderClass="sort-header" class="table custom-table">
            <template #head>
              <tr class="bg-light custom-row small">
                <!-- Index -->
                <th></th>

                <!-- Actions -->
                <th class="text-nowrap sticky-column" style="min-width: 100px;">Actions</th>

                <!-- Type -->
                <VTh sortKey="type" class="text-nowrap sticky-column" style="left: 100px; min-width: 100px;">Type</VTh>

                <!-- Name -->
                <VTh sortKey="name" class="text-nowrap sticky-column" style="left: 200px; min-width: 320px;">Name</VTh>

                <!-- PM Notes -->
                <VTh sortKey="pmNotes" class="text-nowrap" style="min-width: 320px;">PM Notes</VTh>

                <!-- Unit Prices -->
                <VTh sortKey="unitPrice" class="text-nowrap" style="min-width: 220px;">Unit Prices</VTh>

                <!-- Country -->
                <VTh sortKey="country" class="text-nowrap" style="min-width: 170px;">Country</VTh>

                <!-- Language Pairs -->
                <VTh sortKey="languagePairs" class="text-nowrap" style="min-width: 220px;">Language Pairs</VTh>

                <!-- Services -->
                <VTh sortKey="services" class="text-nowrap" style="min-width: 220px;">Services</VTh>

                <!-- Currency -->
                <VTh sortKey="currency" class="text-nowrap text-end" style="min-width: 100px;">Currency</VTh>

                <!-- Units -->
                <VTh sortKey="unitPrice" class="text-nowrap" style="min-width: 220px;">Units</VTh>

                <!-- Native Languages -->
                <VTh sortKey="nativeLanguages" class="text-nowrap" style="min-width: 220px;">Native Languages</VTh>

                <!-- Blacklisted? -->
                <VTh sortKey="blacklisted" class="text-nowrap text-end" style="min-width: 100px;">Blacklisted?</VTh>

                <!-- Self Invoicing? -->
                <VTh sortKey="selfInvoicing" class="text-nowrap text-end" style="min-width: 100px;">Self Invoicing?</VTh>

                <!-- Jobs -->
                <VTh sortKey="jobsLookup" class="text-nowrap" style="min-width: 320px;">Jobs</VTh>

                <!-- Onboarding Notes -->
                <VTh sortKey="notes" class="text-nowrap" style="min-width: 320px;">Onboarding Notes</VTh>

                <!-- Created By -->
                <VTh sortKey="addedBy" class="text-nowrap" style="min-width: 220px;">Created By</VTh>

                <!-- Created On -->
                <VTh sortKey="createdOn" class="text-nowrap text-end" style="min-width: 150px;">Created On</VTh>

                <!-- Opps Won in € -->
                <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="totalBilled" defaultSort="desc" class="text-nowrap text-end" style="min-width: 150px;">Opps Won in €</VTh>

                <!-- Vendor Costs in € -->
                <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="totalCosts" class="text-nowrap text-end" style="min-width: 150px;">Vendor Costs in €</VTh>

                <!-- Profit in € -->
                <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end" style="min-width: 150px;">Profit in €</VTh>

                <!-- Margin -->
                <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="margin" class="text-nowrap text-end" style="min-width: 100px;">Margin</VTh>
              </tr>
            </template>
            <template #body="{rows}">
              <VTr v-for="(row, index) in rows" :key="index" :row="row" v-expandable class="custom-row small">
                <!-- Index -->
                <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td>

                <!-- Actions -->
                <td class="text-nowrap align-middle sticky-column" style="min-width: 100px;">
                  <!-- Opens in new tab -->
                  <a :href="`/vendors/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary" style="background: #fff !important;"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                  <!-- Opens in same tab -->
                  <!-- <router-link :to="`/vendors/${row.id}`" class="btn btn-sm btn-outline-secondary">
                    <i class="bi bi-box-arrow-up-right small"></i> Open
                  </router-link> -->
                </td>

                <!-- Type -->
                <td class="text-nowrap align-middle sticky-column" style="left: 100px; min-width: 100px">
                  <span :class="['badge custom-badge', mixinGetVendorTypeBadgeClass(row.type)]">{{ row.type }}
                  </span>
                </td>

                <!-- Name -->
                <td class="text-nowrap align-middle sticky-column" style="left: 200px;">
                  <div class="text-truncate" style="max-width: 300px;">
                    <strong>{{ row.name }}</strong>
                  </div>
                  <span class="text-muted small">{{ row.email }}</span>
                </td>

                <!-- PM Notes -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.pmNotes }}
                  </div>
                </td>

                <!-- Unit Prices -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    {{ row.unitPrice ? row.unitPrice.split(', ').map(price => getCurrencySymbol[row.currency] + price).join(', ') : '' }}
                  </div>
                </td>

                <!-- Country -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 150px;">
                    <span :class="['badge custom-badge', mixinGetCountryBadgeClass(row.country)]">{{ row.country }}</span>
                  </div>
                </td>

                <!-- Language Pairs -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    <div v-if="row.languagePairs">
                      <span v-for="(pair, index) in row.languagePairs" :key="index">
                        {{ pair.replace('>', '›') }}
                      </span>
                    </div>
                  </div>
                </td>

                <!-- Services -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    {{ row.services }}
                  </div>
                </td>

                <!-- Currency -->
                <td class="text-nowrap align-middle text-end">
                  <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.currency)]">
                    {{ row.currency }}
                  </span>
                </td>

                <!-- Units -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    {{ row.units }}
                  </div>
                </td>

                <!-- Native Languages -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    {{ row.nativeLanguages }}
                  </div>
                </td>

                <!-- Blacklisted? -->
                <td class="text-nowrap align-middle text-end">
                  {{ row.blacklisted }}
                </td>

                <!-- Self Invoicing -->
                <td class="text-nowrap align-middle text-end">
                  {{ row.selfInvoicing }}
                </td>

                <!-- Jobs -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.jobsLookup }}
                  </div>
                </td>

                <!-- Onboarding Notes -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.notes }}
                  </div>
                </td>

                <!-- Created By -->
                <td class="text-nowrap align-middle">
                  <div class="text-truncate" style="max-width: 200px;">
                    {{ row.addedBy }}
                  </div>
                </td>

                <!-- Created On -->
                <td class="align-middle text-nowrap text-end">
                  {{ formatDate(row.createdOn) }}
                </td>

                <!-- Total Billed in € -->
                <td v-if="mixinUserInRoles(['Admin'])" class="align-middle text-nowrap text-end">
                  € {{ row.totalBilled }}
                </td>

                <!-- Total Costs in € -->
                <td v-if="mixinUserInRoles(['Admin'])" class="align-middle text-nowrap text-end">
                  € {{ row.totalCosts }}
                </td>

                <!-- Profit in € -->
                <td v-if="mixinUserInRoles(['Admin'])" class="align-middle text-nowrap text-end">
                  € {{ row.profit }}
                </td>

                <!-- Margin -->
                <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" :class="mixinGetPercentageClass(row.margin)">
                  {{ mixinFormatMargin(row.margin) }}
                </td>
              </VTr>
            </template>
          </VTable>

          <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
            <p class="mb-0">No results found based on the current filters.</p>
          </div>
        </div>
      </div>

      <div v-else class="text-center text-muted p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VendorsHeader from '@/components/VendorsHeader.vue';

export default {
  name: 'AppVendors',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    VendorsHeader,
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['name', 'email', 'languagePairs', 'nativeLanguages'] }, // Used in the input field
        type: { value: '', keys: ['type'] }, // Used in the dropdown only
        blacklisted: { value: '', keys: ['blacklisted'] },  // Used in the dropdown only
        selfInvoicing: { value: '', keys: ['selfInvoicing'] }, // Used in the dropdown only
        addedBy: { value: '', keys: ['addedBy'] }, // Used in the dropdown only
      },

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,

      selectedBlacklisted: 'No',
      selectedType: 'All',
      selectedSelfInvoicing: 'All',
      selectedAddedBy: 'All',

      hasDuplicates: false,
      duplicateNames: [],
    }
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    uniqueTypes() {
      const types = this.getProperty('vendors').map(vendor => vendor.fields['Type']);
      const uniqueTypes = [...new Set(types)];
      uniqueTypes.sort();
      return ['All', ...uniqueTypes];
    },

    uniqueBlacklistedStatuses() {
      const statuses = this.getProperty('vendors').map(vendor => vendor.fields['Blacklisted?']);
      const uniqueStatuses = [...new Set(statuses)];
      uniqueStatuses.sort();
      return ['All', ...uniqueStatuses];
    },

    uniqueSelfInvoicingStatuses() {
      const statuses = this.getProperty('vendors').map(vendor => vendor.fields['Self Invoicing?']);
      const uniqueStatuses = [...new Set(statuses)];
      uniqueStatuses.sort();
      return ['All', ...uniqueStatuses];
    },

    uniqueAddedBy() {
      const people = this.getProperty('vendors').flatMap(vendor => vendor.fields['Added By Lookup']);
      const uniquePeople = [...new Set(people.filter(Boolean))];
      uniquePeople.sort();
      return ['All', ...uniquePeople];
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.getProperty('vendors').map(vendor => {
        let createdOnValue = vendor.fields['Created On'];
        let createdOnDate = null;

        if (typeof createdOnValue === 'string' && createdOnValue !== '') {
          createdOnDate = new Date(createdOnValue);
        }

        return {
          id: vendor.id,
          type: vendor.fields['Type'],
          name: vendor.fields['Full Name'],
          email: vendor.fields['Email'],
          country: vendor.fields['Country Lookup'] ? vendor.fields['Country Lookup'].join(', ') : '',
          languagePairs: vendor.fields['Language Pairs Lookup'] ? vendor.fields['Language Pairs Lookup'].join(', ') : '',
          services: vendor.fields['Services Lookup'] ? [...new Set(vendor.fields['Services Lookup'])].join(', ') : '',
          units: vendor.fields['Units Lookup'] ? [...new Set(vendor.fields['Units Lookup'])].join(', ') : '',
          unitPrice: vendor.fields['Unit Price Lookup'] ? [...new Set(vendor.fields['Unit Price Lookup'])].join(', ') : '',
          nativeLanguages: vendor.fields['Native Languages Lookup'] ? vendor.fields['Native Languages Lookup'].join(', ') : '',
          blacklisted: vendor.fields['Blacklisted?'],
          selfInvoicing: vendor.fields['Self Invoicing?'],
          currency: vendor.fields['Currency'],
          jobsLookup: vendor.fields['Jobs Lookup'] ? vendor.fields['Jobs Lookup'].slice(0, 10).join(', ') + (vendor.fields['Jobs Lookup'].length > 10 ? '...' : '') : '',
          notes: vendor.fields['Notes'] ? vendor.fields['Notes'].trim() : '', // Trimming the notes here
          pmNotes: vendor.fields['PM Notes'] ? vendor.fields['PM Notes'].trim() : '', // Trimming the notes here
          addedBy: vendor.fields['Added By Lookup'] ? vendor.fields['Added By Lookup'].join(', ') : '',
          createdOn: createdOnDate,
          totalBilled: (vendor.fields['Total Billed in €']).toFixed(2),
          totalCosts: (vendor.fields['Total Costs in €']).toFixed(2),
          profit: (vendor.fields['Profit']).toFixed(2),
          margin: vendor.fields['Margin'],
        };
      });
    },

    findDuplicates() {
      const nameCount = {};
      const duplicates = [];
      const originalNames = {};

      this.getProperty('vendors').forEach(vendor => {
        const originalName = vendor.fields['Full Name'];
        const name = originalName.toLowerCase(); // convert to lowercase for comparison
        originalNames[name] = originalName; // store the original name
        if (nameCount[name]) {
          nameCount[name]++;
        } else {
          nameCount[name] = 1;
        }
      });

      Object.keys(nameCount).forEach(name => {
        if (nameCount[name] > 1) {
          duplicates.push(originalNames[name]); // push the original name, not the lowercase version
        }
      });

      return duplicates;
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    formatDate(date) {
      if (date instanceof Date && !isNaN(date.getTime())) {
        return date.getDate() + ' ' + date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear();
      }
      return '';
    },

    selectType(type) {
      this.selectedType = type;
      const typeFilterValue = type === 'All' ? '' : type;
      this.filters.type.value = typeFilterValue;
      this.currentPage = 1;
    },

    selectBlacklisted(status) {
      this.selectedBlacklisted = status;
      const blacklistedFilterValue = status === 'All' ? '' : status;
      this.filters.blacklisted.value = blacklistedFilterValue;
      this.currentPage = 1;
    },

    selectSelfInvoicing(status) {
      this.selectedSelfInvoicing = status;
      const selfInvoicingFilterValue = status === 'All' ? '' : status;
      this.filters.selfInvoicing.value = selfInvoicingFilterValue;
      this.currentPage = 1;
    },

    selectAddedBy(person) {
      this.selectedAddedBy = person;
      const addedByFilterValue = person === 'All' ? '' : person;
      this.filters.addedBy.value = addedByFilterValue;
      this.currentPage = 1;
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
    },
  },

  async mounted() {
    document.title = `Vendors`;
  },

  async created() {
    this.mixinCheckUserAuthentication();

    this.selectBlacklisted(this.selectedBlacklisted);
    this.selectType(this.selectedType);
    this.selectSelfInvoicing(this.selectedSelfInvoicing);
    this.selectAddedBy(this.selectedAddedBy);
  },
};
</script>