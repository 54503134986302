<!-- OppDetailsJobsTab.vue -->

<template>

  <div id="jobs" class="tab-pane">
    <div class="p-0 shadow-sm bg-white rounded">
      <div class="p-4 pb-2 mb-3">
        <div class="row align-items-center">
          <div class="col">
            <span class="fs-5 fw-semibold mb-3">Jobs ({{ jobs.length  }})</span>
          </div>
          <div class="col text-end" v-if="mixinUserInRoles(['Admin', 'PM', 'SDR'])">
            <button type="button" class="btn btn-sm btn-primary" @click="openCreateJobModal"><i class="bi bi-plus-lg"></i> Add Jobs</button>
          </div>
        </div>
      </div>

      <div v-if="!isFetching('fetchingOppJobs') && isDataFetched">
        <div v-if="jobs.length > 0">
          <div class="table-responsive">
            <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" sortHeaderClass="sort-header" class="table custom-table">
              <template #head>
                <tr class="bg-light custom-row small">
                  <th style="min-width: 50px;"></th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap sticky-column" style="min-width: 120px;">Actions</th>
                  <th class="text-nowrap sticky-column text-end" style="left: 120px; min-width: 60px;">Job ID</th>
                  <VTh sortKey="service" class="text-nowrap sticky-column" style="left: 180px; min-width: 200px;">Service</VTh>
                  <VTh sortKey="languagePair" defaultSort="asc" class="text-nowrap sticky-column" style="left: 380px;">Language Pair</VTh>
                  <!-- <th class="text-nowrap text-end" style="">Opp Currency</th> -->
                  <th class="text-nowrap" style="">Unit</th>
                  <th class="text-nowrap text-end" style="">Units</th>
                  <th class="text-nowrap text-end" style="">Unit Price</th>
                  <th class="text-nowrap text-end" style="">Sub Total</th>
                  <th class="text-nowrap text-end" style="">Disc %</th>
                  <th class="text-nowrap text-end" style="">Total</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap" style="">Vendor</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Currency</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap" style="">Vendor Unit</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Units</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Unit Price</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Sub Total</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Disc %</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap text-end" style="">Vendor Total</th>
                  <th class="text-nowrap" style="">Title</th>
                  <th class="text-nowrap" style="">Notes</th>
                  <th class="text-nowrap text-end" style="">MateCat Price</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap" style="">MateCat Link</th>
                  <th v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap" style="">Translated.com Offer</th>
                  <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end" style="">Profit in €</VTh>
                  <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="margin" class="text-nowrap text-end" style="">Margin</VTh>
                  <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="invoice" class="text-nowrap" style="">Invoice</VTh>
                  <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="checked" class="text-nowrap text-end" style="">Checked?</VTh>
                  <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="invoicingNotes" class="text-nowrap" style="">Invoicing Notes</VTh>
                </tr>
              </template>
              <template #body="{rows}">
                <VTr v-for="(row, index) in rows" :key="index" :row="row" v-expandable class="custom-row small">
                  <!-- Index -->
                  <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td>

                  <!-- Actions -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle sticky-column" style="min-width: 120px;">
                    <div class="d-flex align-items-center">
                      <!-- Edit -->
                      <a
                        title="Edit"
                        @click="openUpdateJobModal(row.id)"
                        class="btn btn-sm btn-outline-secondary"
                        style="background: #fff !important;"
                      >
                        <i class="bi bi-pencil-fill small"></i>
                      </a>

                      <!-- Clone -->
                      <a
                        title="Clone"
                        @click="cloneJob(row.id)"
                        :disabled="isCreating('creatingJob')"
                        class="btn btn-sm btn-outline-secondary ms-1"
                        style="background: #fff !important;"
                      >
                        <i class="bi bi-stickies-fill small"></i>
                      </a>

                      <!-- Delete -->
                      <a
                        title="Delete"
                        @click="openDeleteJobModal(row.id)"
                        class="btn btn-sm btn-outline-secondary ms-1"
                        style="background: #fff !important;"
                      >
                        <i class="bi bi-trash-fill small"></i>
                      </a>
                    </div>
                  </td>

                  <!-- Job ID -->
                  <td class="text-nowrap align-middle text-end sticky-column" style="left: 120px; min-width: 60px;">{{ row.jobId }}</td>

                  <!-- Service -->
                  <td class="text-nowrap align-middle sticky-column" style="left: 180px; min-width: 200px;">{{ row.service }}</td>

                  <!-- Language Pair -->
                  <td class="text-nowrap align-middle sticky-column border-end" style="left: 380px;">{{ row.languagePair.replace('>', '›') }}</td>

                  <!-- Opp Currency -->
                  <!-- <td class="text-nowrap align-middle text-end"><span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.oppCurrency)]">{{ row.oppCurrency }}</span></td> -->

                  <!-- Unit -->
                  <td class="text-nowrap align-middle">{{ row.unit }}</td>

                  <!-- Units -->
                  <td class="text-nowrap align-middle text-end">{{ row.units }}</td>

                  <!-- Unit Price -->
                  <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.unitPrice) }}</td>

                  <!-- Sub Total -->
                  <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.subTotal) }}</td>

                  <!-- Disc % -->
                  <td class="text-nowrap align-middle text-end">{{ row.discount }}<span v-if="row.discount">%</span></td>

                  <!-- Total -->
                  <td class="text-nowrap align-middle text-end border-end">{{ getCurrencySymbol[row.oppCurrency] }}{{ mixinFormatNumber(row.total) }}</td>

                  <!-- Vendor -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.vendor }}</td>

                  <!-- Vendor Currency -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end"><span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.vendorCurrency)]">{{ row.vendorCurrency }}</span></td>

                  <!-- Vendor Unit -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.vendorUnit }}</td>

                  <!-- Vendor Units -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end">{{ row.vendorUnits }}</td>

                  <!-- Vendor Unit Price -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.vendorCurrency] }}{{ mixinFormatNumber(row.vendorUnitPrice) }}</td>

                  <!-- Vendor Sub Total -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.vendorCurrency] }}{{ mixinFormatNumber(row.vendorSubTotal) }}</td>

                  <!-- Vendor Disc % -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end">{{ row.vendorDiscount }}</td>

                  <!-- Vendor Total -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end border-end">{{ getCurrencySymbol[row.vendorCurrency] }}{{ mixinFormatNumber(row.vendorTotal) }}</td>

                  <!-- Title -->
                  <td class="text-nowrap align-middle">{{ row.title }}</td>

                  <!-- Notes -->
                  <td class="text-nowrap align-middle">{{ row.notes }}</td>

                  <!-- MateCat Price -->
                  <td class="text-nowrap align-middle text-end">{{ row.matecatPrice }}</td>

                  <!-- MateCat Link -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.matecatLink }}</td>

                  <!-- Translated.com Offer -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.translatedOffer }}</td>

                  <!-- Profit in € -->
                  <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end">€{{ mixinFormatNumber(row.profit) }}</td>

                  <!-- Margin -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end border-end" :class="mixinGetPercentageClass(row.margin)">{{ row.margin }}%</td>

                  <!-- Invoice -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.invoice }}</td>

                  <!-- Checked? -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end">{{ row.checked }}</td>

                  <!-- Invoicing Notes -->
                  <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle">{{ row.invoicingNotes }}</td>
                </VTr>
              </template>
            </VTable>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          No jobs found.
        </div>
      </div>

      <div v-else class="d-flex justify-content-center p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Create Job -->
  <CreateJobModal ref="createJobModal" :oppId="oppId" :opp1UsdToEur="opp1UsdToEur" :opp1GbpToEur="opp1GbpToEur" :oppCurrency="oppCurrency" @showAlert="showAlert" @showError="showError" @fetchJobs="fetchRelatedRecords" @fetchOpp="fetchOpp" />

  <!-- Update Job -->
  <UpdateJobModal ref="updateJobModal" :jobId="selectedJobId" :opp1UsdToEur="opp1UsdToEur" :opp1GbpToEur="opp1GbpToEur" :oppCurrency="oppCurrency" @showAlert="showAlert" @showError="showError" @fetchJobs="fetchRelatedRecords" @fetchOpp="fetchOpp" />

  <!-- Delete Job -->
  <DeleteJobModal ref="deleteJobModal" :jobId="selectedJobId" :jobNiceId="selectedJobNiceId" @showAlert="showAlert" @showError="showError" @fetchJobs="fetchRelatedRecords" @fetchOpp="fetchOpp" />

</template>

<script>
import { mapGetters } from 'vuex';
import CreateJobModal from '@/components/CreateJobModal.vue';
import UpdateJobModal from '@/components/UpdateJobModal.vue';
import DeleteJobModal from '@/components/DeleteJobModal.vue';

export default {
  name: 'OppDetailsJobsTab',
  emits: [
    'showAlert',
    'showError',
    'fetchOpp',
  ],
  props: {
    oppId: {
      type: String,
      required: true,
    },

    opp1UsdToEur: {
      type: Number,
      required: true,
    },

    opp1GbpToEur: {
      type: Number,
      required: true,
    },

    oppCurrency: {
      type: [String, Object],
      required: true,
    },
  },
  components: {
    CreateJobModal,
    UpdateJobModal,
    DeleteJobModal,
  },

  data() {
    return {
      isDataFetched: false,

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 500,

      jobs: { },

      selectedJobId: '',
      selectedJobNiceId: '',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    tableRows() {
      return this.jobs.map(row => {
        return {
          id: row.id,
          jobId: row.fields['Job ID'],
          service: row.fields['Service ID Lookup'] ? row.fields['Service ID Lookup'].join(', ') : '',
          languagePair: row.fields['Language Pair Lookup'] ? row.fields['Language Pair Lookup'].join(', ') : '',
          oppCurrency: row.fields['Opp Currency'] ? row.fields['Opp Currency'].join(', ') : '',
          unit: row.fields['Unit ID Lookup'] ? row.fields['Unit ID Lookup'].join(', ') : '',
          units: row.fields['Units'],
          unitPrice: row.fields['Unit Price'],
          subTotal: row.fields['1 - Sub Total in Opp Currency'].toFixed(2),
          discount: row.fields['Disc %'],
          total: row.fields['3 - Total in Opp Currency'].toFixed(2),
          vendor: row.fields['Vendors Lookup'] ? row.fields['Vendors Lookup'].join(', ') : '',
          vendorCurrency: row.fields['Vendor Currency'],
          vendorUnit: row.fields['Vendor Unit ID Lookup'] ? row.fields['Vendor Unit ID Lookup'].join(', ') : '',
          vendorUnits: row.fields['Vendor Units'],
          vendorUnitPrice: row.fields['Vendor Unit Price'],
          vendorSubTotal: row.fields['1 - Vendor Sub Total in Vendor Currency (Formula)'],
          vendorDiscount: row.fields['2 - Vendor Disc in %'],
          vendorTotal: row.fields['4 - Vendor Total in Vendor Currency'],
          title: row.fields['Title'],
          notes: row.fields['Notes'],
          matecatLink: row.fields['MateCat Link'],
          matecatPrice: row.fields['MateCat Price'],
          translatedOffer: row.fields['Translated.com Offer'],
          profit: row.fields['Profit in €'],
          margin: typeof row.fields['Margin'] === 'number' ? parseFloat(row.fields['Margin']).toFixed(2) : null,
          invoice: row.fields['Invoice'],
          checked: row.fields['Checked?'],
          invoicingNotes: row.fields['Invoicing Notes'],
        };
      });
    },
  },

  watch: {

  },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    fetchOpp() {
      this.$emit('fetchOpp');
    },

    async openCreateJobModal() {
      this.$refs.createJobModal.openModal();
    },

    async openUpdateJobModal(jobId) {
      this.selectedJobId = jobId;
      this.$refs.updateJobModal.openModal();
    },

    async openDeleteJobModal(jobId) {
      this.selectedJobId = jobId;
      const job = this.jobs.find(job => job.id === jobId);
      this.selectedJobNiceId = job.fields['Job ID'];

      this.$refs.deleteJobModal.openModal();
    },

    async cloneJob(jobId) {
      const job = this.jobs.find(job => job.id === jobId);

      const newJob = {
        'Opp ID': job.fields['Opp ID'],
        'Language Pair': job.fields['Language Pair'],
        'Service ID': job.fields['Service ID'],
        'Unit ID': job.fields['Unit ID'],
        'Units': job.fields['Units'],
        'Unit Price': job.fields['Unit Price'],
        '1 GBP to EUR': job.fields['1 GBP to EUR'],
        '1 USD to EUR': job.fields['1 USD to EUR'],

        // mandatory fields
        "Vendors": ["recZxC6XPv36Pwzaw"],
        "Vendor Currency": "Other",
        "Vendor Unit ID": ["rec6MUCC2wo369ZLX"],
      };

      try {
        await this.$store.dispatch('createRecord', {
          tableName: 'Jobs',
          newRecord: newJob,
          creatingStateVariable: 'creatingJob',
        });

        this.$emit('showAlert', 'success', `Successfully cloned Job ${job.fields['Job ID']}`);

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error cloning Job ${job.fields['Job ID']}: ${error.message}`);
        console.error('Error cloning job:', error);

      } finally {
        this.$emit('fetchOpp');
        this.fetchRelatedRecords();

      }
    },

    async fetchRelatedRecords() {
      try {
        this.jobs = await this.$store.dispatch('fetchRelatedRecords', {
          parentTableName: 'Opportunities',
          childTableName: 'Jobs',
          recordId: this.oppId,
          relatedFieldName: 'Jobs ID',
          stateVariable: 'oppJobs',
          fetchingStateVariable: 'fetchingOppJobs',
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error fetching related records: ${error.message}`);
        console.error(error);

      }
    }
  },

  async mounted() {
    await this.fetchRelatedRecords();
  },

  created() { },
};
</script>