<!-- AppNavbar.vue -->

<template>

  <!-- Navbar -->
  <nav class="navbar navbar-expand-lg bg-white fixed-top shadow-sm">
    <div class="custom-container navbar-container">
      <a class="navbar-brand" href="#">Language Buró</a>

      <!-- <a class="navbar-brand" href="#">
        <img src="@/assets/images/lb-logo.svg" alt="Language Buró" class="lb-logo">
      </a> -->

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Main Nav -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">

          <!-- Quotes -->
          <li class="nav-item" v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
            <router-link class="nav-link" to="/opps/quotes">Quotes</router-link>
          </li>

          <!-- Orders -->
          <li class="nav-item" v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
            <router-link class="nav-link" to="/opps/orders">Orders</router-link>
          </li>

          <!-- Opportunities -->
          <!-- <li class="nav-item dropdown" v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Opportunities
            </a>
            <ul class="dropdown-menu shadow border-0">
              <li class="small"><router-link class="dropdown-item" to="/opps/quotes">Quotes</router-link></li>
              <li v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="small"><router-link class="dropdown-item" to="/opps/orders">Orders</router-link></li>
            </ul>
          </li> -->

          <!-- Jobs -->
          <li class="nav-item" v-if="mixinUserInRoles(['Admin', 'PM'])">
            <router-link class="nav-link" to="/jobs">Jobs</router-link>
          </li>

          <!-- Clients -->
          <li class="nav-item dropdown" v-if="mixinUserInRoles(['Admin', 'Finance'])">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Clients
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/companies">Companies</router-link></li>
              <li><router-link class="dropdown-item" to="/contacts">Contacts</router-link></li>
            </ul>
          </li>

          <!-- Vendors -->
          <li class="nav-item dropdown" v-if="mixinUserInRoles(['Admin', 'PM', 'VM'])">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Vendors
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/vendors">Vendors</router-link></li>
              <li><router-link class="dropdown-item" to="/vendors/services">Services</router-link></li>
            </ul>
          </li>

          <!-- Settings -->
          <li class="nav-item dropdown" v-if="mixinUserInRoles(['Admin'])">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Settings
            </a>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/settings/services">Services</router-link></li>
              <li><router-link class="dropdown-item" to="/settings/units">Units</router-link></li>
            </ul>
          </li>
        </ul>

        <!-- Search -->
        <div class="d-flex align-items-center" v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
          <form class="d-flex align-items-center" @submit.prevent="handleSearchFormSubmit">
            <input class="form-control bg-white me-2" style="width: 300px;" type="search" placeholder="Type anything..." aria-label="Search" v-model="searchQuery">
            <button class="btn btn-outline-secondary" type="button" @click="handleSearchFormSubmit">Search</button>
          </form>
        </div>

        <!-- "Create New" options -->
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-plus-lg"></i></button>
            <ul class="dropdown-menu shadow border-0 animate slideIn">
              <!-- New Opportunity -->
              <li v-if="mixinUserInRoles(['Admin', 'PM', 'SDR'])"><a class="dropdown-item small cursor-pointer" data-bs-toggle="modal" data-bs-target="#createOppModal"><i class="bi bi-stars me-1"></i> Opportunity</a></li>
              <!-- Divider - Only show if there are multiple options -->
              <li v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])"><hr class="dropdown-divider"></li>
              <!-- New Company -->
              <li v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])"><a class="dropdown-item small cursor-pointer" data-bs-toggle="modal" data-bs-target="#createCompanyModal"><i class="bi bi-building-fill me-1"></i> Company</a></li>
              <!-- New Contact -->
              <li v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])"><a class="dropdown-item small cursor-pointer" data-bs-toggle="modal" data-bs-target="#createContactModal"><i class="bi bi-person-lines-fill me-1"></i> Contact</a></li>
              <!-- New Vendor -->
              <li v-if="mixinUserInRoles(['Admin', 'VM'])"><a class="dropdown-item small cursor-pointer" data-bs-toggle="modal" data-bs-target="#createVendorModal"><i class="bi bi-person-fill me-1"></i> Vendor</a></li>
            </ul>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="ms-2" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <img :src="mixinGetUserPhoto()" class="rounded-circle user-picture" />
            </a>
            <ul class="dropdown-menu shadow border-0 animate slideIn">
              <li><a class="dropdown-item small" href="" @click.prevent="handleLogout()"><i class="bi bi-box-arrow-right me-1"></i> Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Search results modal -->
  <div class="modal fade" id="searchResultsModal" tabindex="-1" aria-labelledby="searchResultsModalLabel" aria-hidden="false" ref="searchResultsModal">
    <div class="modal-dialog modal-dialog-scrollable modal-custom">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Search Results for <span class="bg-warning bg-gradient">{{ searchQuery }}</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div v-if="!isLoadingSearchResults && isDataFetched">
            <SearchResults :opps="allSearchedOpps" :companies="allSearchedCompanies" :contacts="allSearchedContacts" :jobs="allSearchedJobs" />
          </div>
          <div v-else class="text-center text-muted p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Create Opp -->
  <CreateOppModal ref="createOppModal" @showAlert="showAlert" @showError="showError" />

  <!-- Create Vendor -->
  <CreateVendorModal ref="createVendorModal" @showAlert="showAlert" @showError="showError" />

  <!-- Create Company -->
  <CreateCompanyModal ref="createCompanyModal" @showAlert="showAlert" @showError="showError" />

  <!-- Create Contact -->
  <CreateContactModal ref="createContactModal" @showAlert="showAlert" @showError="showError" />

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import SearchResults from '@/components/SearchResults.vue';
import CreateOppModal from '@/components/CreateOppModal.vue';
import CreateVendorModal from '@/components/CreateVendorModal.vue';
import CreateCompanyModal from '@/components/CreateCompanyModal.vue';
import CreateContactModal from '@/components/CreateContactModal.vue';

export default {
  name: 'AppNavbar',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    SearchResults,
    CreateOppModal,
    CreateVendorModal,
    CreateCompanyModal,
    CreateContactModal,
  },

  data() {
    return {
      searchQuery: '',
      user: null,
      isDataFetched: false,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'allSearchedOpps',
      'isLoadingSearchResultsOpps',

      'allSearchedCompanies',
      'isLoadingSearchResultsCompanies',

      'allSearchedContacts',
      'isLoadingSearchResultsContacts',

      'allSearchedJobs',
      'isLoadingSearchResultsJobs',
    ]),

    isLoadingSearchResults() {
      return this.isLoadingSearchResultsOpps || this.isLoadingSearchResultsCompanies || this.isLoadingSearchResultsContacts || this.isLoadingSearchResultsJobs;
    },
  },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    handleLogout() {
      this.$clerk.signOut().then(() => {
        location.reload();
      });
    },

    // Searchs for a query
    async handleSearchFormSubmit() {
      // Trim and lowercase the search query
      const searchQuery = this.searchQuery.trim().toLowerCase();

      // Check if the search query is empty
      if (searchQuery === '') {
        return;
      }

      // Opps filter
      const oppsFilter = `OR(
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Company Lookup}, ', '))) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Contact Lookup}, ', '))) > 0,
        FIND('${searchQuery}', LOWER({Quote No.})) > 0,
        FIND('${searchQuery}', LOWER({OID} & '')) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Email (from "Contact" table)}, ', '))) > 0,
        FIND('${searchQuery}', LOWER({Invoice})) > 0,
        FIND('${searchQuery}', LOWER({Title})) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Created By Lookup}, ', '))) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({PM Lookup}, ', '))) > 0
      )`;

      // Companies filter
      const companiesFilter = `OR(
        FIND('${searchQuery}', LOWER({Name})) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Contacts}, ', '))) > 0,
        FIND('${searchQuery}', LOWER({Contacts Emails})) > 0
      )`;

      // Contacts filter
      const contactsFilter = `OR(
        FIND('${searchQuery}', LOWER({Name})) > 0,
        FIND('${searchQuery}', LOWER({Email})) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Companies}, ', '))) > 0
      )`;

      // Jobs filter
      const jobsFilter = `OR(
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Company}, ', '))) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Contact}, ', '))) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Contact Email}, ', '))) > 0,
        FIND('${searchQuery}', LOWER(ARRAYJOIN({Quote No.}, ', '))) > 0
      )`;

      try {
        this.isDataFetched = false;
        this.openSearchResultsModal();

        const [
          oppsResults,
          companiesResults,
          contactsResults,
          jobsResults,
        ] = await Promise.all([
          this.$store.dispatch('searchOpps', { filterString: oppsFilter }),
          this.$store.dispatch('searchCompanies', { filterString: companiesFilter }),
          this.$store.dispatch('searchContacts', { filterString: contactsFilter }),
          this.$store.dispatch('searchJobs', { filterString: jobsFilter }),
        ]);

        if (oppsResults || companiesResults || contactsResults || jobsResults) {
          this.isDataFetched = true;
        } else {
          this.$emit('showAlert', 'danger', 'Result from the search dispatch is undefined');
          console.error("Result from the search dispatch dispatch is undefined");

        }

      } catch (error) {
        this.$emit('showAlert', 'danger', 'There was an issue fetching records: ' + error);
        console.error('There was an issue fetching records:', error);

      }
    },

    // Open search results modal
    openSearchResultsModal() {
      const modalElement = this.$refs.searchResultsModal;
      const modalInstance = new bootstrap.Modal(modalElement);
      modalInstance.show();
    },
  },

  created() {
    this.user = this.$clerk.user;
  },
}
</script>