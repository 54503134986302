<!-- ReusableCompaniesTable.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <div v-if="records.length > 0">
      <!-- Table filters and search -->
      <div class="filters mb-1 p-2 ps-0 pe-0">
        <div class="d-flex align-items-center">
          <!-- Filters -->
          <div v-if="showFilters" class="d-flex align-items-center">
            <!-- Filter: Acquired By -->
            <div class="dropdown me-2">
              <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="acquiredByDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Acquired By: <strong>{{ selectedAcquiredBy }}</strong>
              </button>
              <div class="dropdown-menu shadow border-0" aria-labelledby="acquiredByDropdownMenuButton">
                <h6 class="dropdown-header">Filter by Acquired By</h6>
                <a
                  class="dropdown-item d-flex justify-content-between align-items-center small"
                  :class="{ active: selectedAcquiredBy === acquirer.acquirer }"
                  href="#"
                  v-for="acquirer in acquiredByData"
                  :key="acquirer.acquirer"
                  @click.prevent="selectAcquiredBy(acquirer.acquirer)"
                >
                  <span>{{ acquirer.acquirer }} ({{ acquirer.count }})</span>
                  <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(acquirer.revenue) }}</span>
                </a>
              </div>
            </div>

            <!-- Filter: First Order -->
            <div class="d-flex align-items-center me-2">
              <div>
                <VueDatePicker
                  v-model="filters.firstOrder.value"
                  range
                  :enable-time-picker="false"
                  :preset-dates="firstOrderPresetDates"
                  input-class-name="custom-date-picker"
                  hide-input-icon
                  format="dd MMM yyyy"
                  placeholder="Filter by First Order (not working)"
                />
              </div>
            </div>

            <!-- Filter: Last Order -->
            <div class="d-flex align-items-center">
              <div>
                <VueDatePicker
                  v-model="filters.lastOrder.value"
                  :enable-time-picker="false"
                  :preset-dates="lastOrderPresetDates"
                  input-class-name="custom-date-picker"
                  hide-input-icon
                  format="'Last Order:' dd MMM yyyy"
                  placeholder="Filter by Last Order (not working)"
                />
              </div>
            </div>
          </div>

          <!-- Showing text, Pagination and Search -->
          <div :class="['d-flex align-items-center', { 'ms-auto': showFilters }]">
            <!-- Showing text -->
            <div class="text-muted small me-3">{{ showingText }}</div>

            <!-- Pagination -->
            <VTPagination
              v-model:currentPage="currentPage"
              :totalPages="totalPages"
              :maxPageLinks="3"
              :boundaryLinks="false"
              class="me-2"
            >
              <template #next>
                <span class="prevent-select">Next</span>
              </template>

              <template #previous>
                <span class="prevent-select">Previous</span>
              </template>
            </VTPagination>

            <!-- Search -->
            <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Name or Email" style="width: 500px;" />
          </div>
        </div>
      </div>

      <!-- Checked rows actions -->
      <div v-if="mixinUserInRoles(['Admin'])">
        <div v-if="selectedRows.length > 0" class="p-2 border-bottom">
          <span class="text-muted small">{{ selectedRows.length }} {{ selectedRows.length > 1 ? 'rows' : 'row' }} selected. Revenue: €{{ mixinFormatNumber(totalRevenueOfSelectedRows) }}, Profit: €{{ mixinFormatNumber(totalProfitOfSelectedRows) }}, Average Margin: {{ mixinFormatMargin(averageMarginOfSelectedRows) }}</span>
        </div>
      </div>

      <div class="table-responsive">
        <VTable
          :data="tableRows"
          :pageSize="pageSize"
          v-model:currentPage="currentPage"
          @totalPagesChanged="totalPages = $event"
          @stateChanged="handleStateChanged"
          :filters="filters"
          sortHeaderClass="sort-header"
          class="table custom-table border"
          selectionMode="multiple"
          :selectOnClick="false"
          selectedClass="bg-light"
        >
          <!-- Commented because of known issue with "select all" checkbox -->
          <!-- <template #head="{ allRowsSelected, toggleAllRows }"> -->
          <template #head="">
            <tr class="custom-row small">
              <!-- <th class="small"> -->
                <!-- Commented because of known issue with "select all" checkbox -->
                <!-- <input type="checkbox" class="form-check-input" :checked="allRowsSelected" @change="toggleAllRows" /> -->
              <!-- </th> -->

              <!-- Index -->
              <!-- <th class="small" style="min-width: 50px;"></th> -->

              <!-- Actions -->
              <th class="text-nowrap sticky-column small" style="min-width: 100px;"></th>

              <VTh sortKey="name" class="text-nowrap sticky-column small" style="left: 100px; min-width: 320px;">Name</VTh>

              <!-- Country -->
              <VTh sortKey="country" class="text-nowrap small" style="min-width: 170px;">Country</VTh>

              <!-- Preferred Currency -->
              <VTh sortKey="preferredCurrency" class="text-nowrap text-end small" style="">Preferred Currency</VTh>

              <!-- Preferred PM -->
              <VTh sortKey="preferredPM" class="text-nowrap small" style="min-width: 220px;">Preferred PM</VTh>

              <!-- Contacts -->
              <VTh sortKey="contacts" class="text-nowrap small" style="min-width: 320px;">Contacts</VTh>

              <!-- Revenue in € -->
              <VTh v-if="mixinUserInRoles(['Admin', 'Finance'])" defaultSort="desc" sortKey="revenue" class="text-nowrap text-end sticky-column small" style="left: 420px; min-width: 150px;">Revenue in €</VTh>

              <!-- Profit in € -->
              <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end small" style="min-width: 150px;">Profit in €</VTh>

              <!-- Margin -->
              <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="margin" class="text-nowrap text-end small" style="min-width: 100px;">Margin</VTh>

              <!-- Late Payer? -->
              <VTh sortKey="latePayer" class="text-nowrap text-end small" style="min-width: 100px;">Late Payer?</VTh>

              <!-- Sector -->
              <VTh sortKey="sector" class="text-nowrap small" style="min-width: 320px;">Sector</VTh>

              <!-- Opps -->
              <th class="text-nowrap small" style="min-width: 320px;">Opps</th>

              <!-- Jobs -->
              <th class="text-nowrap small" style="min-width: 320px;">Jobs</th>

              <!-- Acquired By -->
              <VTh sortKey="acquiredBy" class="text-nowrap small" style="min-width: 220px;">Acquired By</VTh>

              <!-- Acquired On -->
              <VTh :customSort="acquiredOnDateSort" class="text-nowrap text-end small" style="min-width: 170px">Acquired On</VTh>

              <!-- First Order -->
              <VTh :customSort="lastOrderDateSort" class="text-nowrap text-end small" style="min-width: 170px;">First Order</VTh>

              <!-- Last Order -->
              <VTh :customSort="lastOrderDateSort" class="text-nowrap text-end small" style="min-width: 170px;">Last Order</VTh>

              <!-- Ordered Within 3 Months? -->
              <VTh sortKey="orderedWithin3Months" class="text-nowrap text-end small" style="">Ordered Within 3 Months?</VTh>

              <!-- Notes -->
              <th class="text-nowrap small" style="min-width: 320px;">Notes</th>

              <!-- PM Notes -->
              <th class="text-nowrap small" style="min-width: 320px;">PM Notes</th>
            </tr>
          </template>
          <template #body="{rows}">
            <VTr
                v-for="(row, index) in rows"
                :key="index"
                :row="row"
                v-slot="{ }"
                v-expandable
                class="custom-row small"
              >

              <!-- Checkbox -->
              <!-- <td class="align-middle">
                <input type="checkbox" class="form-check-input" :checked="isSelected" @change.stop="toggle" />
              </td> -->

              <!-- Index -->
              <!-- <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td> -->

              <!-- Actions -->
              <td class="text-nowrap align-middle sticky-column border-end" style="min-width: 100px;" @click.stop>
                <!-- Opens in new tab -->
                <a :href="`/companies/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                <!-- Opens in same tab -->
                <!-- <router-link :to="`/companies/${row.id}`" class="btn btn-sm btn-outline-secondary">
                  <i class="bi bi-box-arrow-up-right small"></i> Open
                </router-link> -->
              </td>

              <!-- Name -->
              <td class="text-nowrap align-middle sticky-column border-end" style="left: 100px;"><div class="text-truncate" style="max-width: 300px;">{{ row.name }}</div></td>

              <!-- Country -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 150px;">
                  <span :class="['badge custom-badge', mixinGetCountryBadgeClass(row.country)]">{{ row.country }}</span>
                </div>
              </td>

              <!-- Preferred Currency -->
              <td class="text-nowrap align-middle text-end"><span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.preferredCurrency)]">{{ row.preferredCurrency }}</span></td>

              <!-- Preferred PM -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 200px;">
                  {{ row.preferredPM }}
                </div>
              </td>

              <!-- Contacts -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  <span v-for="(contact, index) in row.contacts" :key="contact.id">
                    <router-link :to="`/contacts/${contact.id}`" class="text-dark text-decoration-none hover-blue">
                      {{ contact.name }}
                    </router-link>
                    <span v-if="index < row.contacts.length - 1">, </span>
                  </span>
                </div>
              </td>

              <!-- Revenue in € -->
              <td v-if="mixinUserInRoles(['Admin', 'Finance'])" class="text-nowrap align-middle text-end sticky-column border-end" style="left: 420px; min-width: 150px;">
                €{{ mixinFormatNumber(row.revenue) }}
              </td>

              <!-- Profit in € -->
              <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" style="min-width: 150px;">
                €{{ mixinFormatNumber(row.profit) }}
              </td>

              <!-- Margin -->
              <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" :class="mixinGetPercentageClass(row.margin)" style="min-width: 100px;">
                {{ mixinFormatMargin(row.margin) }}
              </td>

              <!-- Late Payer? -->
              <td class="text-nowrap align-middle text-end">
                {{ row.latePayer }}
              </td>

              <!-- Sector -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  {{ row.sector }}
                </div>
              </td>

              <!-- Opps -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  <span v-for="(opp, index) in row.opps" :key="opp.id">
                    <router-link :to="`/opps/${opp.id}`" class="text-dark text-decoration-none hover-blue">
                      {{ opp.name }}
                    </router-link>
                    <span v-if="index < row.opps.length - 1">, </span>
                  </span>
                </div>
              </td>

              <!-- Jobs -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 300px;">
                  {{ row.jobs }}
                </div>
              </td>

              <!-- Acquired By -->
              <td class="text-nowrap align-middle">
                <div class="text-truncate" style="max-width: 200px;">
                  {{ row.acquiredBy }}
                </div>
              </td>

              <!-- Acquired On -->
              <td class="text-nowrap align-middle text-end">
                <div class="text-truncate" style="max-width: 150px;">
                </div>
              </td>

              <!-- First Order -->
              <td class="text-nowrap align-middle text-end">
                <div class="text-truncate" style="max-width: 150px;">
                  {{ mixinFormatDate(row.firstOrder) }}
                </div>
              </td>

              <!-- Last Order -->
              <td class="text-nowrap align-middle text-end">
                <div class="text-truncate" style="max-width: 150px;">
                  {{ mixinFormatDate(row.lastOrder) }}
                </div>
              </td>

              <!-- Ordered Within 3 Months? -->
              <td class="text-nowrap align-middle text-end">
                {{ row.orderedWithin3Months }}
              </td>

              <!-- Notes -->
              <td class="text-nowrap align-middle"><div class="text-truncate" style="max-width: 300px;">{{ row.notes }}</div></td>

              <!-- PM Notes -->
              <td class="text-nowrap align-middle"><div class="text-truncate" style="max-width: 300px;">{{ row.pmNotes }}</div></td>
            </VTr>

            <!-- <tr v-if="mixinUserInRoles(['Admin'])" class="tfoot">
              <td colspan="2"></td>
              <td class="sticky-column" style="min-width: 100px;"></td>
              <td class="sticky-column border-end" style="left: 100px;"></td>
              <td colspan="4"></td>
              <td class="text-nowrap align-middle text-end text-muted small sticky-column border-end" style="left: 420px; min-width: 100px;">€{{ mixinFormatNumber(totalFilteredRevenue) }}</td>
              <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredProfit) }}</td>
              <td class="text-nowrap align-middle text-end text-muted small">{{ mixinFormatMargin(averageFilteredMargin) }}</td>
            </tr> -->
          </template>
        </VTable>

        <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
          <p class="mb-0">No results found based on the current filters.</p>
        </div>
      </div>
    </div>

    <div v-else class="text-center text-muted p-5">
      No companies found.
    </div>
  </div>

  <div v-else>
    <p class="p-4">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths, subYears } from 'date-fns';

export default {
  name: 'ReusableCompaniesTable',

  emits: [
    'showAlert',
    'showError',
  ],

  props: {
    records: {
      type: Array,
      required: true,
    },

    showFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['name', 'email'] },

        acquiredBy: { value: '',  keys: ['acquiredBy'] },
        lastOrder: { value: null },
        firstOrder: { value: [] },
      },

      selectedRows: [],

      lastOrderPresetDates: [
        { label: 'Last month', value: endOfMonth(subMonths(new Date(), 1)) },
        { label: '3 months ago', value: endOfMonth(subMonths(new Date(), 3)) },
        { label: '6 months ago', value: endOfMonth(subMonths(new Date(), 6)) },
        { label: '1 year ago', value: endOfMonth(subYears(new Date(), 1)) },
      ],

      firstOrderPresetDates: [
        { label: 'This month', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
        { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
        { label: 'This year', value: [startOfYear(new Date()), endOfYear(new Date())] },
        { label: 'Last year', value: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))] },
      ],

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      selectedAcquiredBy: 'All',

      totalFilteredItems: 0,
      totalFilteredRevenue: 0,
      totalFilteredProfit: 0,
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',
    ]),

    averageFilteredMargin() {
      if (this.totalFilteredRevenue === 0) {
        return 0;
      }
      return (this.totalFilteredProfit / this.totalFilteredRevenue);
    },

    totalRevenueOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.revenue, 0);
    },

    totalProfitOfSelectedRows() {
      return this.selectedRows.reduce((total, row) => total + row.profit, 0);
    },

    averageMarginOfSelectedRows() {
      const totalProfit = this.totalProfitOfSelectedRows;
      const totalRevenue = this.totalRevenueOfSelectedRows;
      return totalRevenue !== 0 ? (totalProfit / totalRevenue) * 100 : 0;
    },

    // Not used so far
    averageOrderSpan() {
      let totalMonths = 0;
      let count = 0;

      this.records.forEach(record => {
        const acquiredDate = new Date(record.fields['Acquired On']);
        const lastOrderDate = new Date(record.fields['Last Order']);

        if (acquiredDate instanceof Date && lastOrderDate instanceof Date && !isNaN(acquiredDate) && !isNaN(lastOrderDate)) {
          const diff = lastOrderDate.getMonth() - acquiredDate.getMonth() + (12 * (lastOrderDate.getFullYear() - acquiredDate.getFullYear()));
          totalMonths += diff;
          count++;
        }
      });

      return count > 0 ? totalMonths / count : 0;
    },

    uniqueAcquiredBy() {
      const acquiredByList = this.records.map(record => record.fields['Acquired By Lookup'][0]);
      const uniqueAcquiredBy = [...new Set(acquiredByList)];
      uniqueAcquiredBy.sort();
      return ['All', ...uniqueAcquiredBy];
    },

    acquiredByData() {
      const data = this.uniqueAcquiredBy.map(acquirer => {
        let recordsByAcquirer;
        if (acquirer === 'All') {
          recordsByAcquirer = this.records;
        } else {
          recordsByAcquirer = this.records.filter(record => record.fields['Acquired By Lookup'][0] === acquirer);
        }
        return {
          acquirer,
          count: recordsByAcquirer.length,
          revenue: recordsByAcquirer.reduce((sum, record) => {
            if (typeof record.fields['6 - Opps Won in €'] === 'number') {
              return sum + record.fields['6 - Opps Won in €'];
            } else {
              return sum;
            }
          }, 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      // this was working
      // const filteredRecords = this.records.filter(record => {
      //   const lastOrder = record.fields['Last Order'] ? new Date(record.fields['Last Order']).getTime() : Infinity;
      //   const end = this.filters.lastOrder.value ? new Date(this.filters.lastOrder.value).getTime() : Infinity;
      //   return lastOrder <= end;
      // });

      // this we wanted to make it work
      // const filteredRecords = this.records.filter(record => {
      //   const lastOrder = record.fields['Last Order'] ? new Date(record.fields['Last Order']).getTime() : Infinity;
      //   const firstOrder = record.fields['First Order'] ? new Date(record.fields['First Order']).getTime() : -Infinity;

      //   const end = this.filters.lastOrder.value !== null ? new Date(this.filters.lastOrder.value).getTime() : Infinity;
      //   const [acquiredStart, acquiredEnd] = this.filters.firstOrder.value.length > 0 ? this.filters.firstOrder.value.map(date => new Date(date).getTime()) : [-Infinity, Infinity];

      //   return lastOrder <= end &&
      //          (record.fields['First Order'] !== undefined) &&
      //          firstOrder >= acquiredStart &&
      //          firstOrder <= acquiredEnd;
      // });

      return this.records.map(row => {
        let opps = row.fields['Opps Lookup'] ? row.fields['Opps Lookup'] : [];
        let oppIds = row.fields['Opps IDs'] ? row.fields['Opps IDs'] : [];

        let oppsWithIds = opps.map((opp, index) => {
          return {
            name: `${opp}`,
            id: oppIds[index]
          };
        }).sort((a, b) => b.name.localeCompare(a.name, undefined, {numeric: true}));

        let contacts = row.fields['Contacts Lookup'] ? row.fields['Contacts Lookup'] : [];
        let contactIds = row.fields['Contacts IDs'] ? row.fields['Contacts IDs'] : [];
        let contactsWithIds = contacts.map((contact, index) => {
          return {
            name: `${contact}`,
            id: contactIds[index]
          };
        });

        let acquiredOnValue = row.fields['Acquired On'];
        let acquiredOnDate = this.parseDateString(acquiredOnValue);

        return {
          id: row.id,
          name: row.fields['Name'],
          country: row.fields['Country Lookup'] ? row.fields['Country Lookup'].join(', ') : '',
          preferredCurrency: row.fields['Preferred Currency'],
          preferredPM: row.fields['Preferred PM Lookup'] ? row.fields['Preferred PM Lookup'].join(', ') : '',
          preferredPMPhoto: row.fields['Preferred PM Photo'] ? row.fields['Preferred PM Photo'][0].url : '',
          contacts: contactsWithIds,
          revenue: row.fields['6 - Opps Won in €'],
          profit: row.fields['8 - Profit in €'],
          margin: row.fields['9 - Margin'],
          latePayer: row.fields['Late Payer?'],
          sector: row.fields['Sector'],
          opps: oppsWithIds,
          jobs: typeof row.fields['Jobs Lookup'] === 'string' ? row.fields['Jobs Lookup'].split(',').slice(0, 10).join(',') + (row.fields['Jobs Lookup'].split(',').length > 10 ? '...' : '') : row.fields['Jobs Lookup'], // since it's not an array, we convert it to an array in order to get 10 jobs
          acquiredOn: acquiredOnDate,
          acquiredBy: row.fields['Acquired By Lookup'] ? row.fields['Acquired By Lookup'].join(', ') : '',
          acquiredByPhoto: row.fields['Acquired By Photo'] ? row.fields['Acquired By Photo'][0].url : '',
          firstOrder: row.fields['First Order'],
          lastOrder: row.fields['Last Order'],
          orderedWithin3Months: row.fields['Ordered Within 3 Months?'],
          notes: row.fields['Notes'],
          pmNotes: row.fields['PM Notes'],
        };
      });
    },
  },

  watch: {
    'filters.firstOrder.value': function() {
      this.selectedRows.splice(0, this.selectedRows.length);
    },

    'filters.lastOrder.value': function() {
      this.selectedRows.splice(0, this.selectedRows.length);
    },

    'filters.name.value': function() {
      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;
    },
  },

  methods: {
    selectAcquiredBy(acquiredBy) {
      this.selectedAcquiredBy = acquiredBy;
      const acquiredByFilterValue = acquiredBy === 'All' ? '' : acquiredBy;
      this.filters.acquiredBy.value = acquiredByFilterValue;
      this.currentPage = 1;
    },

    acquiredOnDateSort(a, b, sortOrder) {
      let date1 = new Date(a.acquiredOn).getTime();
      let date2 = new Date(b.acquiredOn).getTime();

      if (sortOrder === 1) {
        return date1 - date2
      } else if (sortOrder === -1) {
        return date2 - date1
      } else {
        return 0
      }
    },

    lastOrderDateSort(a, b, sortOrder) {
      let date1 = a.lastOrder ? new Date(a.lastOrder).getTime() : -Infinity;
      let date2 = b.lastOrder ? new Date(b.lastOrder).getTime() : -Infinity;

      if (sortOrder === 1) {
        return date1 - date2
      } else if (sortOrder === -1) {
        return date2 - date1
      } else {
        return 0
      }
    },

    parseDateString(dateString) {
      if (typeof dateString !== 'string') {
        return new Date(0); // return a very old date
      }

      if (dateString === 'Check Old DB') {
        return new Date(0); // return a very old date
      }

      let parts = dateString.split(' ');
      let month = new Date(Date.parse(parts[0] +" 1, 2012")).getMonth() + 1;
      let year = parseInt(parts[1]);

      return new Date(year, month - 1);
    },

    formatAcquiredOnDate(date) {
      if (date instanceof Date && !isNaN(date.getTime())) {
        return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
      }
      return '';
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
      this.selectedRows = tableState.selectedRows;

      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;

      for (let row of tableState.rowsPrePagination) {
        this.totalFilteredRevenue += row.revenue || 0;
        this.totalFilteredProfit += row.profit || 0;
      }
    },
  },

  created() { },
};
</script>