<!-- JobsHeader.vue -->

<template>

  <div v-if="!isFetching('fetchingJobs')">
    <div class="d-flex mb-3 border-bottom align-items-end">
      <div class="col-4 d-flex align-items-end">
        <div class="p-2 ps-0">
          <div class="d-flex align-items-center">
            <div class="fs-5 fw-semibold me-2">
              Jobs
            </div>

            <!-- View Picker -->
            <div class="dropdown me-2">
              <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="monthDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <strong>{{ selectedMonth.label }}</strong>
              </button>
              <div class="dropdown-menu shadow border-0 dropdown-menu-scroll" aria-labelledby="monthDropdownMenuButton">
                <h6 class="dropdown-header">Select a View</h6>
                <a
                  class="dropdown-item small"
                  href="#"
                  v-for="month in allMonths"
                  :key="month.value"
                  @click.prevent="selectMonth(month)"
                >
                  <span>{{ month.label }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="text-center text-muted p-5">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'JobsHeader',
  emits: [
    'showAlert',
    'showError',
    'selectMonth',
  ],
  props: {
    selectedMonth: {
      type: Object,
      required: true,
    },
  },

  data() { },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'allMonths',
    ]),
  },

  watch: { },

  methods: {
    selectMonth(month) {
      this.$emit("selectMonth", month.value);
    }
  },

  created() { },
};
</script>