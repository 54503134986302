<!-- Quotes.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <QuotesHeader
      :selectedMonth="selectedMonth"
      :quotes="records"
      @showAlert="showAlert"
      @showError="showError"
      @totalRevenueChanged="handleTotalRevenueChanged"
      @selectMonth="mixinSelectMonth"
    />

    <!-- Navigation -->
    <div v-if="!isFetching('fetchingQuotes')">
      <div class="row mb-3">
        <div class="col">
          <ul class="nav nav-pills" id="tabNav" v-show="tabsVisible">
            <li class="nav-item small">
              <a class="nav-link" :class="{ 'active': activeTab === 'quotes' }" @click.prevent="setActiveTab('quotes')" data-bs-toggle="tab" href="#quotes">Quotes</a>
            </li>

            <li class="nav-item small" v-if="mixinUserInRoles(['Admin'])">
              <a class="nav-link" :class="{ 'active': activeTab === 'reports' }" @click.prevent="setActiveTab('reports')" data-bs-toggle="tab" href="#reports">Reports</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content">
      <!-- Quotes -->
      <div id="quotes" class="tab-pane" :class="{ 'show active': activeTab === 'quotes' }">
        <div v-if="!isFetching('fetchingQuotes')">
          <div class="shadow-sm bg-white p-3 rounded">
            <!-- Table filters and search -->
            <div class="filters mb-1 p-2 ps-0 pe-0">
              <div class="d-flex align-items-center">
                <!-- Filters -->
                <div class="d-flex align-items-center">
                  <!-- Filter: Status -->
                  <div class="dropdown me-2">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="statusDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Status: <strong>{{ selectedStatus }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0" aria-labelledby="statusDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Status</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedStatus === status.status }"
                        href="#"
                        v-for="status in statusData"
                        :key="status.status"
                        @click.prevent="selectStatus(status.status)"
                      >
                        <span>{{ status.status }} ({{ status.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(status.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <!-- Filter: Created By -->
                  <div class="dropdown me-2">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="createdByDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Created By: <strong>{{ selectedCreatedBy }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0" aria-labelledby="createdByDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Created By</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedCreatedBy === creator.creator }"
                        href="#"
                        v-for="creator in createdByData"
                        :key="creator.creator"
                        @click.prevent="selectCreatedBy(creator.creator)"
                      >
                        <span>{{ creator.creator }} ({{ creator.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(creator.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <!-- Filter: Company -->
                  <div class="dropdown me-2" v-if="mixinUserInRoles(['Admin'])">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="companyDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Company: <strong>{{ selectedCompany }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0 dropdown-menu-scroll" aria-labelledby="companyDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Company</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedCompany === company.company }"
                        href="#"
                        v-for="company in companyData"
                        :key="company.company"
                        @click.prevent="selectCompany(company.company)"
                      >
                        <span class="text-truncate" style="max-width: 300px;">{{ company.company }} ({{ company.count }})</span>
                        <span class="text-muted small ms-2">€{{ mixinFormatNumber(company.revenue) }}</span>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Showing text, Pagination and Search -->
                <div class="d-flex align-items-center ms-auto">
                  <!-- Showing text -->
                  <div class="text-muted small me-3">{{ showingText }}</div>

                  <!-- Pagination -->
                  <VTPagination
                    v-model:currentPage="currentPage"
                    :totalPages="totalPages"
                    :maxPageLinks="3"
                    :boundaryLinks="false"
                    class="me-2"
                  >
                    <template #next>
                      <span class="prevent-select">Next</span>
                    </template>

                    <template #previous>
                      <span class="prevent-select">Previous</span>
                    </template>
                  </VTPagination>

                  <!-- Search -->
                  <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Quote, Company, Contact, Email or Created By" style="width: 450px;" />
                </div>
              </div>
            </div>

            <!-- Table -->
            <div class="table-responsive">
              <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" @stateChanged="handleStateChanged" :filters="filters" sortHeaderClass="sort-header" class="table custom-table border">
                <template #head>
                  <tr class="custom-row small">
                    <!-- Index -->
                    <th class="small" style="min-width: 50px;"></th>

                    <!-- Actions -->
                    <th class="text-nowrap sticky-column small" style="min-width: 100px;">
                      <!-- Actions -->
                    </th>

                    <!-- Status -->
                    <VTh sortKey="status" class="text-nowrap sticky-column small" style="left: 100px; min-width: 120px;">
                      Status
                    </VTh>

                    <!-- Opp -->
                    <th class="text-nowrap text-end sticky-column small" style="left: 220px; min-width: 100px;">
                      Opp
                    </th>

                    <!-- Revenue -->
                    <VTh sortKey="value" class="text-nowrap text-end sticky-column small" style="left: 320px; min-width: 100px;">
                      Revenue
                    </VTh>

                    <!-- Currency -->
                    <VTh sortKey="currency" class="text-nowrap text-end small" style="">
                      Currency
                    </VTh>

                    <!-- Client -->
                    <VTh sortKey="company" class="text-nowrap sticky-column small" style="left: 420px; min-width: 320px;">
                      Client
                    </VTh>

                    <!-- Created On -->
                    <VTh defaultSort="desc" :customSort="dateSort('createdOn')" class="text-nowrap text-end small" style="">
                      Created On
                    </VTh>

                    <!-- Created By -->
                    <VTh sortKey="createdBy" class="text-nowrap small" style="">
                      Created By
                    </VTh>

                    <!-- Forwarded By -->
                    <VTh sortKey="forwardedBy" class="text-nowrap small" style="">
                      Forwarded By
                    </VTh>

                    <!-- Language Pairs -->
                    <!-- <th class="text-nowrap" style="min-width: 320px;">
                      Language Pairs
                    </th> -->

                    <!-- Title -->
                    <!-- <th class="text-nowrap" style="min-width: 320px;">
                      Title
                    </th> -->

                    <!-- Private Notes -->
                    <!-- <th class="text-nowrap" style="min-width: 320px;">
                      Private Notes
                    </th> -->
                  </tr>
                </template>
                <template #body="{rows}">
                  <template v-for="(row, index) in rows" :key="index">
                    <VTr :row="row" v-expandable class="custom-row small">
                      <!-- Index -->
                      <td class="text-nowrap align-middle text-end text-muted small">
                        {{ (currentPage - 1) * pageSize + index + 1 }}
                      </td>

                      <!-- Actions -->
                      <td class="text-nowrap align-middle sticky-column" style="min-width: 100px;" @click.stop>
                        <!-- Opens in new tab -->
                        <a :href="`/opps/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                        <!-- Opens in same tab -->
                        <!-- <router-link :to="`/opps/${row.id}`" class="btn btn-sm btn-outline-secondary">
                          <i class="bi bi-box-arrow-up-right small"></i> Open
                        </router-link> -->

                        <!-- "Expand" button -->
                        <button @click="expandedRows[index] = !expandedRows[index]" class="btn btn-sm btn-outline-secondary ms-2">
                          <i class="bi bi-arrows-angle-expand"></i>
                        </button>
                      </td>

                      <!-- Status -->
                      <td class="text-nowrap align-middle sticky-column" style="left: 100px; min-width: 120px;">
                        <span :class="['badge custom-badge', mixinGetBadgeClass(row.status)]">
                          {{ row.status.replace('Quote: ', '') }}
                        </span>
                      </td>

                      <!-- Opp -->
                      <td class="text-nowrap align-middle text-end sticky-column" style="left: 220px; min-width: 100px';">
                        <a :href="`https://app.languageburo.com/opps/${row.oppId}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ row.quoteNumber }}</a>
                      </td>

                      <!-- Revenue -->
                      <td class="text-nowrap align-middle text-end sticky-column" style="left: 320px; min-width: 100px;">
                        {{ getCurrencySymbol[row.currency] }}{{ mixinFormatNumber(row.value) }}
                      </td>

                      <!-- Currency -->
                      <td class="text-nowrap align-middle text-end" style="">
                        <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.currency)]">{{ row.currency }}</span>
                      </td>

                      <!-- Client -->
                      <td class="text-nowrap align-middle sticky-column" style="left: 420px;">
                        <div class="text-truncate" style="max-width: 300px;">
                          <a :href="`/companies/${row.companyId}`" target="_blank" class="text-dark text-decoration-none hover-blue">
                            {{ row.company }}
                          </a>
                          <br>
                          <span class="text-muted small">
                            <a :href="`/contacts/${row.contactId}`" target="_blank" class="text-muted text-decoration-none hover-blue">
                              {{ row.contact }}
                            </a>
                          </span>
                          <br>
                          <span class="text-muted small">
                            {{ row.contactEmail }}
                          </span>
                        </div>
                      </td>

                      <!-- Created On -->
                      <td class="text-nowrap align-middle text-end">
                        {{ formatDate(row.createdOn) }}
                      </td>

                      <!-- Created By -->
                      <td class="text-nowrap align-middle small">
                        <span class="user border p-0 pe-2 d-inline-block">
                          <img :src="row.createdByPhoto" class="rounded-circle user-picture-small"> {{ row.createdBy }}
                        </span>
                      </td>

                      <!-- Forwarded By -->
                      <td class="text-nowrap align-middle small">
                        <span :class="row.forwardedBy === 'Unassigned' ? '' : 'user border p-0 pe-2 d-inline-block'">
                          <img :src="row.forwardedByPhoto ? row.forwardedByPhoto : ''" class="rounded-circle user-picture-small">
                          {{ row.forwardedBy }}
                        </span>
                      </td>

                      <!-- Language Pairs -->
                      <!-- <td class="text-wrap align-middle">
                        <div class="text-truncate" style="max-width: 300px;">
                          <div v-if="row.languagePairs">
                            <span v-for="(pair, index) in row.languagePairs" :key="index">
                              {{ pair.replace('>', '›') }}
                            </span>
                          </div>
                        </div>
                      </td> -->

                      <!-- Title -->
                      <!-- <td class="text-nowrap align-middle">
                        <div class="text-truncate" style="max-width: 300px;">
                          {{ row.title }}
                        </div>
                      </td> -->

                      <!-- Private Notes -->
                      <!-- <td class="text-nowrap align-middle">
                        <div class="text-truncate" style="max-width: 300px;">
                          {{ row.privateNotes }}
                        </div>
                      </td> -->
                    </VTr>

                    <VTr v-if="expandedRows[index]" :row="row" class="custom-row small">
                      <td colspan="100%" class="text-muted small p-4 bg-light">
                        <div v-if="row.title" style="white-space: pre-line;" class="mb-3">
                          <p class="fw-bold mb-2">Title:</p>

                          <div class="ms-2 ps-2 border-start">
                            {{ row.title }}
                          </div>
                        </div>

                        <div v-if="row.privateNotes" style="white-space: pre-line;" class="mb-3">
                          <p class="fw-bold mb-2">Private Notes:</p>

                          <div class="ms-2 ps-2 border-start">
                            {{ row.privateNotes }}
                          </div>
                        </div>
                        <div v-if="row.mateCatLink" style="white-space: pre-line;" class="mb-3">
                          <p class="fw-bold mb-2">MateCat Link:</p>

                          <div class="ms-2 ps-2 border-start">
                            {{ row.mateCatLink }}
                          </div>
                        </div>

                        <div v-if="row.jobs">
                          <p class="fw-bold mb-2">Jobs: </p>

                          <div class="ms-2 ps-2 border-start">
                            <div v-html="row.jobs"></div>
                          </div>
                        </div>
                      </td>
                    </VTr>
                  </template>

                  <tr class="tfoot" v-if="mixinUserInRoles(['Admin'])">
                    <td class="sticky-column" colspan="4"></td>
                    <td class="text-nowrap align-middle text-end text-muted small sticky-column" style="left: 320px; min-width: 100px;">€{{ mixinFormatNumber(totalFilteredRevenue) }}</td>
                    <td colspan="16"></td>
                  </tr>
                </template>
              </VTable>

              <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
                <p class="mb-0">No results found based on the current filters.</p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

      <!-- Reports -->
      <div id="reports" class="tab-pane" :class="{ 'show active': activeTab === 'reports' }">
        <div v-if="!isFetching('fetchingQuotes')">
          <div class="p-0 shadow-sm bg-white rounded mb-3">
            <!-- Section header  -->
            <div class="p-4 pb-2">
              <div class="row align-items-center">
                <div class="col">
                  <span class="fs-5 fw-semibold mb-3">Reports</span>
                </div>
              </div>
            </div>

            <div class="p-4 pt-2">

              <ReportOppsByTeamMember :records="records" />

              <ReportOppsByCompany :records="records" />

            </div>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import QuotesHeader from '@/components/QuotesHeader.vue';
import ReportOppsByTeamMember from '@/components/ReportOppsByTeamMember.vue';
import ReportOppsByCompany from '@/components/ReportOppsByCompany.vue';

export default {
  name: 'AppQuotes',

  emits: [
    'showAlert',
    'showError',
  ],

  components: {
    QuotesHeader,
    ReportOppsByTeamMember,
    ReportOppsByCompany,
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['quoteNumber', 'company', 'contact', 'contactEmail', 'createdBy'], custom: this.nameFilter },
        status: { value: '', keys: ['status'] }, // Used in the dropdown only
        createdBy: { value: '', keys: ['createdBy'] }, // Used in the dropdown only
        company: { value: '', keys: ['company'] }, // Used in the dropdown only
      },

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,

      selectedStatus: 'All',
      selectedCreatedBy: 'All',
      selectedCompany: 'All',

      selectedMonth: {
        label: '',
        value: '',
      },

      records: [],

      totalFilteredRevenue: 0,

      totalRevenue: 0,

      expandedRows: {},

      tabsVisible: false,
      activeTab: 'quotes',

      tableName: 'Opportunities',
      stateVariable: 'quotes',
      fetchingStateVariable: 'fetchingQuotes',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'getCurrencySymbol',
      'allMonths',
    ]),

    uniqueStatuses() {
      const statuses = this.records.map(row => row.fields['Status']);
      const quoteStatuses = statuses.filter(status => !status.startsWith('Order:'));
      const uniqueStatuses = [...new Set(quoteStatuses)];
      uniqueStatuses.sort();
      return ['All', ...uniqueStatuses];
    },

    statusData() {
      const statusOrder = ['Open', 'Won', 'Abandoned', 'Lost'];
      const data = this.uniqueStatuses.map(status => {
        let quotesWithStatus;
        if (status === 'All') {
          quotesWithStatus = this.records;
        } else {
          quotesWithStatus = this.records.filter(row => row.fields['Status'] === status);
        }
        return {
          status: status.replace('Quote: ', ''),
          count: quotesWithStatus.length,
          revenue: quotesWithStatus.reduce((sum, row) => sum + row.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

      return data;
    },

    uniqueCreatedBy() {
      const createdByList = this.records.map(row => row.fields['Created By Lookup'][0]);
      const uniqueCreatedBy = [...new Set(createdByList)];
      uniqueCreatedBy.sort();
      return ['All', ...uniqueCreatedBy];
    },

    createdByData() {
      const data = this.uniqueCreatedBy.map(creator => {
        let quotesByCreator;
        if (creator === 'All') {
          quotesByCreator = this.records;
        } else {
          quotesByCreator = this.records.filter(row => row.fields['Created By Lookup'][0] === creator);
        }
        return {
          creator,
          count: quotesByCreator.length,
          revenue: quotesByCreator.reduce((sum, row) => sum + row.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    uniqueCompanies() {
      const companyList = this.records.map(row => row.fields['Company Lookup'][0]);
      const uniqueCompanies = [...new Set(companyList)];
      uniqueCompanies.sort();
      return ['All', ...uniqueCompanies];
    },

    companyData() {
      const data = this.uniqueCompanies.map(company => {
        let quotesByCompany;
        if (company === 'All') {
          quotesByCompany = this.records;
        } else {
          quotesByCompany = this.records.filter(row => {
            if (row.fields['Company Lookup']) {
              return row.fields['Company Lookup'][0] === company;
            }
            return false;
          });
        }

        // Count the number of unique companies
        const uniqueCompanies = new Set(quotesByCompany.map(row => row.fields['Company Lookup'][0]));

        return {
          company,
          count: company === 'All' ? uniqueCompanies.size : 1,
          revenue: quotesByCompany.reduce((sum, row) => sum + row.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.records.map(row => {
        // This method creates the table of jobs, and calculates the average vendor unit price and other stuff.
        // It's very complicated and VERY hacky, but it works to give a better pricing estimate. Known issues:
        // - MateCat Price currency is hard coded
        // - Vendor Price currency is hard coded
        // - calculateVendorUnitPriceAvg should check for vendor services prices converted to EUR. Right now, it seems to take the
        //   price no matter the currency

        const services = row.fields['Jobs Service Rollup'] ? row.fields['Jobs Service Rollup'].split(',') : [];
        const languagePairs = row.fields['Jobs Language Pair Rollup'] ? row.fields['Jobs Language Pair Rollup'].split(',') : [];
        const units = row.fields['Jobs Unit Rollup'] ? row.fields['Jobs Unit Rollup'].split(',') : [];
        const unitsCount = row.fields['Jobs Units Rollup'] ? row.fields['Jobs Units Rollup'].split(',') : [];
        const unitPrices = row.fields['Jobs Unit Price Rollup'] ? row.fields['Jobs Unit Price Rollup'].split(',') : [];
        const prices = row.fields['Jobs Price Rollup'] ? row.fields['Jobs Price Rollup'].split(',') : [];
        const mateCatWeightedCounts = row.fields['Jobs MateCat Weighted Rollup'] ? row.fields['Jobs MateCat Weighted Rollup'].split(',') : [];
        const mateCatPrices = row.fields['Jobs MateCat Price Rollup'] ? row.fields['Jobs MateCat Price Rollup'].split(',') : [];

        const vendorBlacklisted = row.fields['Jobs Vendor Services Blacklisted Rollup'] ? row.fields['Jobs Vendor Services Blacklisted Rollup'].split(',') : [];
        const vendorLanguagePairs = row.fields['Jobs Vendor Services Language Pair Rollup'] ? row.fields['Jobs Vendor Services Language Pair Rollup'].split(',') : [];
        const vendorServices = row.fields['Jobs Vendor Services Service Rollup'] ? row.fields['Jobs Vendor Services Service Rollup'].split(',') : [];
        const vendorUnits = row.fields['Jobs Vendor Services Unit Rollup'] ? row.fields['Jobs Vendor Services Unit Rollup'].split(',') : [];
        const vendorUnitPrices = row.fields['Jobs Vendor Services Unit Price Rollup'] ? row.fields['Jobs Vendor Services Unit Price Rollup'].split(',') : [];

        // Define the table headers
        const tableHeaders = `
          <tr class="border-bottom">
            <th class="bg-transparent">Service</th>
            <th class="bg-transparent">Language Pair</th>
            <th class="bg-transparent">Unit</th>
            <th class="bg-transparent text-end">Units</th>
            <th class="bg-transparent text-end">Unit Price</th>
            <th class="bg-transparent text-end">Total</th>
            <th class="bg-transparent text-end">MateCat WWC</th>
            <th class="bg-transparent text-end">MateCat Price</th>
            <th class="bg-transparent text-end">Margin w/MateCat</th>
            <th class="bg-transparent text-end">Avg. Vendor Unit Price</th>
            <th class="bg-transparent text-end">Vendor Price</th>
            <th class="bg-transparent text-end">Margin w/Vendor</th>
          </tr>`;

        // Map each job to a table row
        const jobRows = services.map((service, index) => {
          const languagePair = languagePairs[index] ? languagePairs[index].trim() : 'N/A';
          const unitType = units[index] ? units[index].trim() : 'N/A';
          const totalUnits = unitsCount[index] ? unitsCount[index] : '1';
          const currencyCode = row.fields['Currency'];
          const currencySymbol = this.getCurrencySymbol[currencyCode];
          const unitPrice = unitPrices[index] ? `${currencySymbol}${this.mixinFormatNumber(unitPrices[index])}` : 'N/A';
          const totalPrice = prices[index] ? parseFloat(prices[index]) : 0; // Ensure this is a number
          const mateCatWeighted = mateCatWeightedCounts[index] ? mateCatWeightedCounts[index].trim() : '';
          const mateCatPrice = mateCatPrices[index] ? mateCatPrices[index].trim() : '';

          //  debug
          // console.log('-------------');
          // console.log(`Quote No.:`, row.fields['Quote No.']);
          // console.log(`service at index ${index}:`, service);
          // console.log(`languagePair at index ${index}:`, languagePair);
          // console.log(`unitType at index ${index}:`, unitType);
          // console.log(`totalUnits at index ${index}:`, totalUnits);
          // console.log(`currencyCode:`, currencyCode);
          // console.log(`currencySymbol:`, currencySymbol);
          // console.log(`unitPrice at index ${index}:`, unitPrice);
          // console.log(`totalPrice at index ${index}:`, totalPrice);
          // console.log(`mateCatWeighted at index ${index}:`, mateCatWeighted);
          // console.log(`mateCatPrice at index ${index}:`, mateCatPrice);

          // Calculate the average vendor unit price
          const vendorUnitPriceAvg = this.calculateVendorUnitPriceAvg(
            vendorBlacklisted,
            vendorLanguagePairs,
            vendorServices,
            vendorUnits,
            vendorUnitPrices,

            languagePair, // the language pair we want to match in vendorServices
            service, // the vendor service we want to match in vendorServices
            unitType, // the vendor unit we want to match in vendorUnits
          );

          const formattedLanguagePair = languagePair.replace(/ > /g, ' › ');
          const formattedVendorUnitPriceAvg = vendorUnitPriceAvg !== null ? this.mixinFormatNumber(vendorUnitPriceAvg, 3) : 'N/A';

          // Parse mateCatWeighted as a number to ensure correct multiplication
          const mateCatWeightedNum = parseFloat(mateCatWeightedCounts[index] || '0');

          let calculationBase;

          if (mateCatWeightedNum === 0) {
            // If MateCat Weighted Word Count is 0, use the units count as the base for calculation.
            calculationBase = parseFloat(unitsCount[index] || '0');
          } else {
            // If MateCat Weighted Word Count is not 0, use it as the base for calculation.
            calculationBase = mateCatWeightedNum;
          }

          // Ensure vendorUnitPriceAvg is a number; parse or calculate it as needed
          const vendorUnitPriceAvgNum = parseFloat((parseFloat(vendorUnitPriceAvg || '0')).toFixed(3));

          // Calculate Estimated Vendor Price using the appropriate base (MateCat WWC or Units)
          const estimatedVendorPrice = calculationBase * vendorUnitPriceAvgNum;

          // Format the estimated vendor price for display
          const formattedEstimatedVendorPrice = this.mixinFormatNumber(estimatedVendorPrice);

          // Format totalPrice for display
          const formattedTotalPrice = `${currencySymbol}${this.mixinFormatNumber(totalPrice)}`;

          // Initialize margin as a string to potentially hold "N/A"
          let margin;

          // Check if mateCatPrice is a valid number
          const mateCatPriceNumber = parseFloat(mateCatPrice);

          // Initialize a variable to hold the formatted display value
          let formattedMateCatPrice;

          // Check if the parsed price is a valid number and not NaN
          if (!isNaN(mateCatPriceNumber) && mateCatPriceNumber !== 0) {
            // If valid, format the display string with the € symbol
            formattedMateCatPrice = `€${mateCatPriceNumber.toFixed(2)}`; // toFixed(2) to format it as a fixed-point notation with 2 decimal places
          } else {
            // If not a valid number, you can decide what to display, e.g., 'N/A' or an empty string
            formattedMateCatPrice = 'N/A';
          }

          if (totalPrice > 0 && !isNaN(mateCatPriceNumber)) { // Ensure totalPrice is greater than 0 and mateCatPrice is a number to avoid division by zero
              margin = ((totalPrice - mateCatPriceNumber) / totalPrice) * 100;
              // Format margin as a percentage string with two decimal places
              margin = margin.toFixed(2) + "%";
          } else {
              // Set margin to "N/A" if mateCatPrice is not a valid number
              margin = "";
          }

          // Calculate Margin with vendor
          let marginWithVendor = 0;
          if (totalPrice > 0) { // Prevent division by zero
            marginWithVendor = ((totalPrice - estimatedVendorPrice) / totalPrice) * 100;
          }

          // Format the margin for display, rounding to two decimal places
          const formattedMarginWithVendor = marginWithVendor.toFixed(2) + '%';

          return `
            <tr>
              <td>${service}</td>
              <td>${formattedLanguagePair}</td>
              <td>${unitType}</td>
              <td class="text-end">${totalUnits}</td>
              <td class="text-end">${unitPrice}</td>
              <td class="text-end">${formattedTotalPrice}</td>
              <td class="text-end">${mateCatWeighted}</td>
              <td class="text-end">${formattedMateCatPrice}</td>
              <td class="text-end">${margin}</td>
              <td class="text-end">${formattedVendorUnitPriceAvg}</td>
              <td class="text-end">€${formattedEstimatedVendorPrice}</td>
              <td class="text-end">${formattedMarginWithVendor}</td>
            </tr>`;
        }).join('');

        // Combine the headers and rows to form the complete table
        const jobsTable = `
          <table class="table common-table table-sm text-muted mb-0">
            ${tableHeaders}
            ${jobRows}
          </table>`;

        return {
          id: row.id,
          status: row.fields['Status'],
          quoteNumber: row.fields['Quote No.'],
          oppId: row.fields['Opp ID'],
          // value: row.fields['9 - Gross Revenue in €'],
          value: row.fields['4 - Total in Opp Currency'],
          currency: row.fields['Currency'],
          company: row.fields['Company Lookup'] ? row.fields['Company Lookup'].join(', ') : '',
          companyId: row.fields['Company ID'] ? row.fields['Company ID'].join(', ') : '',
          contact: row.fields['Contact Lookup'] ? row.fields['Contact Lookup'].join(', ') : '',
          contactId: row.fields['Contact ID'] ? row.fields['Contact ID'].join(', ') : '',
          contactEmail: row.fields['Email Lookup'] ? row.fields['Email Lookup'].join(', ') : '',
          createdOn: this.convertToDate(row.fields['Created On']),
          createdBy: row.fields['Created By Lookup'] ? row.fields['Created By Lookup'].join(', ') : '',
          createdByPhoto: row.fields['Created By Photo'] ? row.fields['Created By Photo'][0].url : '',
          forwardedBy: row.fields['Forwarded By Lookup'] ? row.fields['Forwarded By Lookup'].join(', ') : '',
          forwardedByPhoto: row.fields['Forwarded By Photo'] ? row.fields['Forwarded By Photo'][0].url : '',
          pm: row.fields['PM Lookup'] ? row.fields['PM Lookup'].join(', ') : '',
          languagePairs: row.fields['Language Pairs'] ? row.fields['Language Pairs'].split(',').map(pair => pair.trim()).join(', '): '',
          title: row.fields['Title'],
          privateNotes: row.fields['Private Notes'] ? row.fields['Private Notes'].trim() : '', // Trimming the notes here
          mateCatLink: row.fields['MateCat Link'] ? row.fields['MateCat Link'].trim() : '', // Trimming the notes here
          publicNotes: row.fields['Public Notes'] ? row.fields['Public Notes'].trim() : '', // Trimming the notes here
          invoice: row.fields['Invoice'],
          deliveryConfirmed: row.fields['Delivery Confirmed?'],
          jobs: jobsTable,
        };
      });
    },
  },

  watch: {
    'filters.name.value': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Reset expandedRows to collapse all rows when the search filter changes
        this.expandedRows = {};
        // Recalculate the totalFilteredRevenue if needed
        this.totalFilteredRevenue = 0;

        // Update the URL's query parameters to include the new search value
        this.$router.replace({ query: { ...this.$route.query, search: newVal } }).catch(err => {
          // Ignore the navigation duplicate error
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
  },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    setActiveTab(tabName) {
      this.activeTab = tabName;
      // This next line is crucial: it manually triggers Bootstrap's tab show action
      const tabElement = document.querySelector(`#tabNav .nav-link[href="#${tabName}"]`);
      if (tabElement) {
        new bootstrap.Tab(tabElement).show();
      }
    },

    calculateVendorUnitPriceAvg(vendorBlacklisted, vendorLanguagePairs, vendorServices, vendorUnits, vendorUnitPrices, languagePairToMatch, serviceToMatch, unitToMatch) {
      // Assuming languagePairToMatch is a string like "English › German"
      // console.log(languagePairToMatch)

      const filteredPrices = vendorServices.reduce((prices, service, index) => {
        const blacklisted = vendorBlacklisted[index] === 'Yes';
        const matchesService = service === serviceToMatch;
        const matchesUnit = vendorUnits[index] === unitToMatch;
        const matchesLanguagePair = vendorLanguagePairs[index] === languagePairToMatch;

        if (matchesService && matchesUnit && !blacklisted && matchesLanguagePair) {
          const price = parseFloat(vendorUnitPrices[index]);
          prices.push(price);
        }
        return prices;
      }, []);

      if (filteredPrices.length > 0) {
        const sum = filteredPrices.reduce((sum, price) => sum + price, 0);
        const avg = sum / filteredPrices.length;
        return avg;
      } else {
        return null;
      }
    },

    handleTotalRevenueChanged(newTotalRevenue) {
      this.totalRevenue = newTotalRevenue;
    },

    nameFilter(filterValue, row) {
      return this.filters.name.keys.some(key => String(row[key]).toLowerCase().includes(filterValue.toLowerCase()));
    },

    selectStatus(status) {
      this.selectedStatus = status;
      const statusFilterValue = status === 'All' ? '' : status;
      this.filters.status.value = statusFilterValue;
      this.currentPage = 1;
      this.expandedRows = {};

      // Update the URL's query parameters
      this.$router.replace({
        query: { ...this.$route.query, status: statusFilterValue }
      }).catch(() => {});
    },

    selectCreatedBy(createdBy) {
      this.selectedCreatedBy = createdBy;
      const createdByFilterValue = createdBy === 'All' ? '' : createdBy;
      this.filters.createdBy.value = createdByFilterValue;
      this.currentPage = 1;
      this.expandedRows = {};

      // Update the URL's query parameters
      this.$router.replace({
        query: { ...this.$route.query, createdBy: createdBy === 'All' ? '' : createdBy }
      }).catch(() => {});
    },

    selectCompany(company) {
      this.selectedCompany = company;
      const companyFilterValue = company === 'All' ? '' : company;
      this.filters.company.value = companyFilterValue;
      this.currentPage = 1;
      this.expandedRows = {};

      // Update the URL's query parameters
      this.$router.replace({
        query: { ...this.$route.query, company: company === 'All' ? '' : company }
      }).catch(() => {});
    },

    convertToDate(dateString) {
      if (typeof dateString === 'string' && dateString !== '') {
        return dateString;
      }
      return null;
    },

    dateSort(sortKey) {
      return (a, b, sortOrder) => {
        let date1 = new Date(a[sortKey]).getTime();
        let date2 = new Date(b[sortKey]).getTime();

        if (sortOrder === 1) {
          return date1 - date2
        } else if (sortOrder === -1) {
          return date2 - date1
        } else {
          return 0
        }
      }
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;

      this.totalFilteredRevenue = 0;

      for (let row of tableState.rowsPrePagination) {
        this.totalFilteredRevenue += row.value || 0;
      }
    },

    formatDate(dateString) {
      if (typeof dateString === 'string' && dateString !== '') {
        let date = new Date(dateString);
        return date.getDate() + ' ' + date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear();
      }
      return '';
    },

    getFilterString(currentMonth, currentYear) {
      const monthIndex = new Date(Date.parse(currentMonth +" 1, 2012")).getMonth() + 1;
      const firstDayOfMonth = `01-${currentMonth}-${currentYear}`;
      const lastDayOfMonth = new Date(currentYear, monthIndex, 0).getDate() + `-${currentMonth}-${currentYear}`;

      return `AND(
        OR(
          {Status} = "Quote: Open",
          {Status} = "Quote: Won",
          {Status} = "Quote: Abandoned",
          {Status} = "Quote: Lost"
        ),
        OR(
          IS_AFTER({Created On}, DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY')),
          {Created On} = DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY')
        ),
        OR(
          IS_BEFORE({Created On}, DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY')),
          {Created On} = DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY')
        )
      )`;
    },

    getFilterStringForPeriod(startDate, endDate) {
      const start = `${startDate.getDate()}-${startDate.getMonth()+1}-${startDate.getFullYear()}`;
      const end = `${endDate.getDate()}-${endDate.getMonth()+1}-${endDate.getFullYear()}`;

      return `AND(
        OR(
          {Status} = "Quote: Open",
          {Status} = "Quote: Won",
          {Status} = "Quote: Abandoned",
          {Status} = "Quote: Lost"
        ),
        OR(
          IS_AFTER({Created On}, DATETIME_PARSE('${start}', 'DD-MM-YYYY')),
          {Created On} = DATETIME_PARSE('${start}', 'DD-MM-YYYY')
        ),
        OR(
          IS_BEFORE({Created On}, DATETIME_PARSE('${end}', 'DD-MM-YYYY')),
          {Created On} = DATETIME_PARSE('${end}', 'DD-MM-YYYY')
        )
      )`;
    },

    async fetchRecordsForPeriod(startDate, endDate) {
      const filterString = this.getFilterStringForPeriod(startDate, endDate);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for period ${startDate} - ${endDate}:`, error);
      }
    },

    async fetchRecords(month, year) {
      const filterString = this.getFilterString(month, year);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for ${month} - ${year}:`, error);
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.setActiveTab(this.activeTab);
    });
  },

  async created() {
    this.mixinCheckUserAuthentication();

    // document.title = `Quotes: €${this.mixinFormatNumber(this.totalRevenue)}`;
    document.title = `Quotes`;

    if (!this.selectedMonth.value) {
      const currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setHours(12, 0, 0);
      const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
      const currentYear = currentDate.getFullYear();
      const monthName = currentDate.toLocaleString('en-US', { month: 'long' });
      this.selectedMonth = {
        label: `${monthName} (${currentYear})`,
        value: `${currentMonth} ${currentYear}`,
      };
    }

    const [month, year] = this.selectedMonth.value.split(' ');
    this.records = await this.fetchRecords(month, year);

    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });

    // Initialize "Search" filter from URL
    if (this.$route.query.search) {
      this.filters.name.value = this.$route.query.search;
    }

    // Initialize "View" filter from URL
    if (this.$route.query.view) {
      this.mixinSelectMonth(this.$route.query.view);
    }

    // Initialize "Status" filter from URL
    if (this.$route.query.status) {
      this.selectStatus(this.$route.query.status);
    }

    // Initialize "Created By" filter from URL
    if (this.$route.query.createdBy) {
      this.selectCreatedBy(this.$route.query.createdBy);
    }

    // Initialize "Company" filter from URL
    if (this.$route.query.company) {
      this.selectCompany(this.$route.query.company);
    }
  },
};
</script>