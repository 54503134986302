<!-- CreateJobModal.vue -->

<template>

  <div class="modal" id="createJobModal" tabindex="-1" aria-labelledby="createJobModalLabel" aria-hidden="true" ref="createJobModal">
    <div class="modal-dialog modal-custom">

      <div class="modal-content shadow shadow-lg">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Create Jobs</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="p-1 mb-2 border rounded">
            <table class="table table-borderless mb-0">
              <thead>
                <tr class="bg-light custom-row small">
                  <th class="text-nowrap" style="width: 30px;"></th>
                  <th class="text-nowrap" style="width: 250px;">Service</th>
                  <th class="text-nowrap" style="width: 300px;">Language Pair</th>
                  <th class="text-nowrap" style="width: 150px;">Unit</th>
                  <th class="text-nowrap text-end" style="width: 150px;">Units</th>
                  <th class="text-nowrap text-end" style="width: 150px;">Unit Price</th>
                  <th class="text-nowrap text-end" style="width: 150px;">Sub Total</th>
                  <th class="text-nowrap text-end" style="width: 150px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in newJobs" :key="index">
                  <!-- No. -->
                  <td class="align-middle text-end text-muted small">
                    {{ index + 1 }}
                  </td>

                  <!-- Service -->
                  <td class="align-middle">
                      <v-select v-model="job.service" :options="allServicesOptions" placeholder="Select..." :clearable="false" style="min-width: 250px;">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>
                  </td>

                  <!-- Language Pair -->
                  <td class="align-middle">
                    <v-select v-model="job.languagePair" :options="allLanguagePairsOptions" placeholder="Select..." :clearable="false" style="min-width: 300px;">
                      <template v-slot:option="option">
                        <div class="text-wrap">{{ option.label.replace('>', '›') }}</div>
                        <!-- <div class="small text-muted">Suggested PPW in Euro: {{ option.price }}</div> -->
                      </template>

                      <template v-slot:selected-option="option">
                        <div>{{ option.label.replace('>', '›') }}</div>
                      </template>

                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>
                  </td>

                  <!-- Unit -->
                  <td class="align-middle">
                    <v-select v-model="job.unit" :options="allUnitsOptions" placeholder="Select..." :clearable="false" style="min-width: 150px;">
                      <template v-slot:open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                        </span>
                      </template>
                    </v-select>
                  </td>

                  <!-- Units -->
                  <td class="align-middle">
                    <input type="number" class="form-control" v-model="job.units">
                  </td>

                  <!-- Unit Price -->
                  <td class="align-middle">
                    <input type="number" class="form-control" v-model="job.unitPrice">
                  </td>

                  <!-- Sub Total -->
                  <td class="text-end align-middle">
                    {{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(calculateSubTotal(job)) }}
                  </td>

                  <!-- Remove -->
                  <td class="align-middle text-end">
                    <button v-if="index > 0" type="button" class="btn btn-danger btn-sm" @click="removeLine(index)">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <button @click.prevent="addOneMoreLine" class="btn btn-secondary btn-sm"><i class="bi bi-plus-lg"></i> Add one more line</button>
        </div>

        <div class="modal-footer">
          <div class="row align-items-center">
            <div class="col-auto">
              Total: {{ getCurrencySymbol[oppCurrency] }}{{ mixinFormatNumber(calculateTotal()) }}
            </div>

            <div class="col">
              <span v-if="isCreating('creatingJobs')" class="me-2 spinner-border spinner-border-sm text-secondary" role="status" aria-hidden="true"></span>
              <button type="button" class="btn btn-primary" @click="createJobs" :disabled="isCreating('creatingJobs')|| isSubmitting">Create Jobs</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';

export default {
  name: 'CreateJobModal',
  emits: [
    'showAlert',
    'showError',
    'fetchJobs',
    'fetchOpp',
  ],
  props: {
    oppId: {
      type: String,
      required: true,
    },

    opp1UsdToEur: {
      type: Number,
      required: true,
    },

    opp1GbpToEur: {
      type: Number,
      required: true,
    },

    oppCurrency: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      newJobs: [this.defaultNewJob()],

      isSubmitting: false,
      modalInstance: null,

      defaultServiceId: 'recPNw0jsPKOCXYFr',
      defaultUnitId: 'recHm4hBaGxWbDDSc',
    };
  },

  computed: {
    ...mapGetters([
      'allLanguagePairsOptions',
      'allServicesOptions',
      'allUnitsOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),
  },

  watch: {
    allServicesOptions: {
      immediate: true,
      handler() {
        if (this.newJobs.length > 0) {
          this.assignDefaultValues(this.newJobs[0]);
        }
      },
    },

    allUnitsOptions: {
      immediate: true,
      handler() {
        if (this.newJobs.length > 0) {
          this.assignDefaultValues(this.newJobs[0]);
        }
      },
    },
  },

  methods: {
    removeLine(index) {
      this.newJobs.splice(index, 1);
    },

    assignDefaultValues(job) {
      const defaultService = this.allServicesOptions.find(service => service.value === this.defaultServiceId);
      const defaultUnit = this.allUnitsOptions.find(unit => unit.value === this.defaultUnitId);

      if (defaultService) {
        job.service = defaultService;
      }

      if (defaultUnit) {
        job.unit = defaultUnit;
      }
    },

    defaultNewJob() {
      return {
        service: { label: '', value: this.defaultServiceId },
        languagePair: '',
        unit: { label: '', value: this.defaultUnitId },
        units: '',
        unitPrice: '',
      };
    },

    openModal() {
      if (!this.modalInstance) {
        const modalElement = this.$refs.createJobModal;
        this.modalInstance = new bootstrap.Modal(modalElement);
      }
      this.modalInstance.show();
    },

    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
        this.resetForm();
      }
    },

    calculateSubTotal(job) {
      const subTotal = job.units * job.unitPrice;
      return parseFloat(subTotal.toFixed(2));
    },

    calculateTotal() {
      return this.newJobs.reduce((total, job) => total + this.calculateSubTotal(job), 0);
    },

    addOneMoreLine() {
      const newJob = this.defaultNewJob();
      this.assignDefaultValues(newJob);
      this.newJobs.push(newJob);
    },

    resetForm() {
      this.newJobs = [this.defaultNewJob()];
      this.assignDefaultValues(this.newJobs[0]);
    },

    validateFields() {
      let errors = [];

      this.newJobs.forEach((job, index) => {
        if (!job.languagePair) { errors.push(`Job ${index + 1}: "Language Pair" is required.`); }
        if (!job.unit) { errors.push(`Job ${index + 1}: "Unit" is required.`); }
        if (!job.units) { errors.push(`Job ${index + 1}: "Units" is required.`); }
        if (!job.unitPrice) { errors.push(`Job ${index + 1}: "Unit Price" is required.`); }
      });

      return errors;
    },

    formatJob(job) {
      const formattedData = {
        'Opp ID': [this.oppId],
        'Service ID': [job.service.value],
        'Language Pair': [job.languagePair.value],
        'Unit ID': [job.unit.value],
        'Units': parseFloat(job.units),
        'Unit Price': parseFloat(job.unitPrice),
        '1 USD to EUR': this.opp1UsdToEur,
        '1 GBP to EUR': this.opp1GbpToEur,

        // mandatory fields
        'Vendors': ['recZxC6XPv36Pwzaw'],
        'Vendor Currency': 'Other',
        'Vendor Unit ID': ['rec6MUCC2wo369ZLX'],
      };

      return formattedData;
    },

    chunkArray(array, size) {
      const chunks = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    },

    async createJobs() {
      this.isSubmitting = true;

      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        this.isSubmitting = false;
        return;
      }

      // console.log('newJobs object:', this.newJobs); // debug

      const formattedJobs = this.newJobs.map((job) => this.formatJob({ ...job }));
      // console.log(formattedJobs); // debug

      // Send jobs in batches of 10, to comply with Airtable limitations
      const batchSize = 10;
      const jobChunks = this.chunkArray(formattedJobs, batchSize);

      try {
        for (const chunk of jobChunks) {
          await this.$store.dispatch('createRecords', {
            tableName: 'Jobs',
            newRecords: chunk,
            creatingStateVariable: 'creatingJobs',
          });

          this.$emit('showAlert', 'success', 'Successfully created job/s');
        }

      } catch (error) {
        this.$emit('showAlert', 'danger', 'Error creating job/s: ' + error.message);
        console.error('Error creating job/s: ', error);

      } finally {
        this.isSubmitting = false;
        this.closeModal();
        this.resetForm();
        this.$emit('fetchJobs');
        this.$emit('fetchOpp');

      }
    },
  },

  async mounted() {
    this.$refs.createJobModal.addEventListener('hidden.bs.modal', this.resetForm());
  },

  created() { },
};
</script>