<!-- VendorsServices.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'VM'])">
    <VendorsServicesHeader @showAlert="showAlert" @showError="showError" />

    <div class="p-3 shadow-sm bg-white rounded">
      <div v-if="!isFetching('fetchingVendorsServices')">
        <!-- Table filters and search -->
        <div class="filters mb-1 p-2 ps-0 pe-0">
          <div class="d-flex align-items-center">
            <!-- Filters -->
            <div class="d-flex align-items-center">
              <!-- Filter: Blacklisted? -->
              <div class="dropdown me-2">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="blacklistedDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Blacklisted? <strong>{{ selectedBlacklisted }}</strong>
                </button>
                <div class="dropdown-menu shadow border-0" aria-labelledby="blacklistedDropdownMenuButton">
                  <h6 class="dropdown-header">Filter by Blacklisted?</h6>
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedBlacklisted === status }"
                    href="#"
                    v-for="status in uniqueBlacklistedStatuses"
                    :key="status"
                    @click.prevent="selectBlacklisted(status)"
                  >
                    {{ status }}
                  </a>
                </div>
              </div>
            </div>

            <!-- Showing text, Pagination and Search -->
            <div class="d-flex align-items-center ms-auto">
              <!-- Showing text -->
              <div class="text-muted small me-3">{{ showingText }}</div>

              <!-- Pagination -->
              <VTPagination
                v-model:currentPage="currentPage"
                :totalPages="totalPages"
                :maxPageLinks="3"
                :boundaryLinks="false"
                class="me-2"
              >
                <template #next>
                  <span class="prevent-select">Next</span>
                </template>

                <template #previous>
                  <span class="prevent-select">Previous</span>
                </template>
              </VTPagination>

              <!-- Search -->
              <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Name, Email, Language Pair or Native Languages" style="width: 450px;" />
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" @stateChanged="handleStateChanged" :filters="filters" sortHeaderClass="sort-header" class="table custom-table">
            <template #head>
              <tr class="bg-light custom-row small">
                <th></th>
                <VTh sortKey="service" class="text-nowrap">Service</VTh>
                <VTh sortKey="languagePair" defaultSort="asc" class="text-nowrap">Language Pair</VTh>
                <VTh sortKey="vendor" class="text-nowrap" style="min-width: 320px;">Vendor</VTh>
                <VTh sortKey="vendor" class="text-nowrap" style="min-width: 320px;">PM Notes</VTh>
                <VTh sortKey="type" class="text-nowrap">Type</VTh>
                <VTh sortKey="currency" class="text-nowrap text-end">Currency</VTh>
                <VTh sortKey="unit" class="text-nowrap">Unit</VTh>
                <VTh sortKey="unitPrice" class="text-nowrap text-end">Unit Price</VTh>
                <VTh sortKey="blacklisted" class="text-nowrap text-end">Blacklisted?</VTh>
                <VTh sortKey="selfInvoicing" class="text-nowrap text-end">Self Invoicing?</VTh>
                <VTh sortKey="nativeLanguages" class="text-nowrap">Native Languages</VTh>
                <VTh sortKey="country" class="text-nowrap">Country</VTh>
                <VTh sortKey="specialities" class="text-nowrap" style="min-width: 320px;">Specialities</VTh>
                <VTh sortKey="notes" class="text-nowrap" style="min-width: 320px;">Notes</VTh>
              </tr>
            </template>
            <template #body="{rows}">
              <VTr v-for="(row, index) in rows" :key="index" :row="row" v-expandable class="custom-row small">
                <!-- Index -->
                <td class="text-nowrap align-middle text-end text-muted small">{{ (currentPage - 1) * pageSize + index + 1 }}</td>

                <!-- Service -->
                <td class="text-nowrap align-middle">{{ row.service }}</td>

                <!-- Language Pair -->
                <td class="text-nowrap align-middle">{{ row.languagePair.replace('>', '›') }}</td>

                <!-- Vendor -->
                <td class="text-nowrap align-middle" style="left: 200px;">
                  <div class="text-truncate" style="max-width: 300px;">
                    <span v-for="(vendor, index) in row.vendor" :key="index">
                      <a :href="`/vendors/${vendor.id}`" target="_blank" class="text-dark text-decoration-none hover-blue">
                        <strong>{{ vendor.name }}</strong>
                      </a>

                      <!-- <router-link :to="`/vendors/${vendor.id}`" class="text-dark text-decoration-none hover-blue">
                        <strong>{{ vendor.name }}</strong>
                      </router-link> -->
                    </span>
                    <br>
                    <span class="text-muted small">
                      {{ row.email }}
                    </span>
                  </div>
                </td>

                <!-- PM Notes -->
                <td class="text-nowrap align-middle" style="">
                  <div class="text-truncate" style="max-width: 300px;">
                    {{ row.pmNotes }}
                  </div>
                </td>

                <!-- Type -->
                <td class="text-nowrap align-middle"><span :class="['badge custom-badge', mixinGetVendorTypeBadgeClass(row.type)]">{{ row.type }}</span></td>

                <!-- Currency -->
                <td class="text-nowrap align-middle text-end"><span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.currency)]">{{ row.currency }}</span></td>

                <!-- Unit -->
                <td class="text-nowrap align-middle">{{ row.unit }}</td>

                <!-- Unit Price -->
                <td class="text-nowrap align-middle text-end">{{ getCurrencySymbol[row.currency] }}{{ row.unitPrice }}</td>

                <!-- Blacklisted? -->
                <td class="text-nowrap align-middle text-end">{{ row.blacklisted }}</td>

                <!-- Self Invoicing? -->
                <td class="text-nowrap align-middle text-end">{{ row.selfInvoicing }}</td>

                <!-- Native Languages -->
                <td class="text-nowrap align-middle">{{ row.nativeLanguages }}</td>

                <!-- Country -->
                <td class="text-nowrap align-middle">{{ row.country }}</td>

                <!-- Specialities -->
                <td class="text-nowrap align-middle"><div class="text-truncate" style="max-width: 300px;">{{ row.specialities }}</div></td>

                <!-- Notes -->
                <td class="text-nowrap align-middle"><div class="text-truncate" style="max-width: 200px;">{{ row.notes }}</div></td>
              </VTr>
            </template>
          </VTable>

          <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
            <p class="mb-0">No results found based on the current filters.</p>
          </div>
        </div>
      </div>

      <div v-else class="text-center text-muted p-5">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VendorsServicesHeader from '@/components/VendorsServicesHeader.vue';

export default {
  name: 'AppVendorsServices',
  emits: [
    'showAlert',
    'showError',
  ],
  components: {
    VendorsServicesHeader,
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['name', 'email', 'languagePair', 'nativeLanguages'] }, // Used in the input field
        blacklisted: { value: '', keys: ['blacklisted'] },  // Used in the dropdown only
      },

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,

      selectedBlacklisted: 'No',

      hasDuplicates: false,
      duplicateNames: [],
    }
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',
      'getProperty',

      'getCurrencySymbol',
    ]),

    uniqueBlacklistedStatuses() {
      // Flatten the array of arrays into a single array
      const statuses = this.getProperty('vendorsServices').flatMap(vendorService => vendorService.fields['Blacklisted Lookup']);
      const uniqueStatuses = [...new Set(statuses)];
      uniqueStatuses.sort();
      return ['All', ...uniqueStatuses];
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.getProperty('vendorsServices').map(row => {
        let vendor = row.fields['Vendor Lookup'] ? [row.fields['Vendor Lookup'][0]] : [];
        let vendorId = row.fields['Vendor ID Lookup'] ? [row.fields['Vendor ID Lookup'][0]] : [];

        let vendorLink = vendor.map((vendor, index) => {
          return {
            name: vendor,
            id: vendorId[index]
          };
        });

        return {
          id: row.id,
          service: row.fields['Service Lookup'] ? row.fields['Service Lookup'].join(', ') : '',
          languagePair: row.fields['Language Pair Lookup'] ? row.fields['Language Pair Lookup'].join(', ') : '',
          currency: row.fields['Currency Lookup'] ? row.fields['Currency Lookup'].join(', ') : '',
          unit: row.fields['Unit Lookup'] ? row.fields['Unit Lookup'].join(', ') : '',
          unitPrice: row.fields['Unit Price'],
          vendor: vendorLink,
          email: row.fields['Email Lookup'] ? row.fields['Email Lookup'].join(', ') : '',
          pmNotes: row.fields['PM Notes Lookup'] ? row.fields['PM Notes Lookup'].join(', ') : '',
          type: row.fields['Type Lookup'] ? row.fields['Type Lookup'].join(', ') : '',
          blacklisted: row.fields['Blacklisted Lookup'] ? row.fields['Blacklisted Lookup'].join(', ') : '',
          selfInvoicing: row.fields['Self Invoicing Lookup'] ? row.fields['Self Invoicing Lookup'].join(', ') : '',
          nativeLanguages: row.fields['Native Languages Lookup'] ? row.fields['Native Languages Lookup'].join(', ') : '',
          country: row.fields['Country Lookup'] ? row.fields['Country Lookup'].join(', ') : '',
          specialities: row.fields['Specialities Lookup'] ? row.fields['Specialities Lookup'].join(', ') : '',
          notes: row.fields['Notes Lookup'] ? row.fields['Notes Lookup'].join(', ') : '',
        };
      });
    },
  },

  watch: { },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    selectBlacklisted(status) {
      this.selectedBlacklisted = status;
      const blacklistedFilterValue = status === 'All' ? '' : status;
      this.filters.blacklisted.value = blacklistedFilterValue;
      this.currentPage = 1;
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;
    },
  },

  async mounted() {
    document.title = `Vendors Services`;
  },

  async created() {
    this.mixinCheckUserAuthentication();

    this.selectBlacklisted(this.selectedBlacklisted);
  },
};
</script>