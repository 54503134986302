<!-- CompanyDetails.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'], isPublicMode)">
    <!-- Breadcrumb -->
    <!-- <nav v-if="isDataFetched" style="--bs-breadcrumb-divider: '&rsaquo;';" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item small"><router-link to="/companies">Companies</router-link></li>
        <li class="breadcrumb-item small active" aria-current="page">{{ record.fields['Name'] }}</li>
      </ol>
    </nav> -->

    <div v-if="isPublicMode" class="alert alert-warning p-2 small">
      <i class="bi bi-exclamation-triangle-fill"></i> <strong>Update your company details using this form.</strong> Please note that any changes will affect only invoices not issued yet. If you need to change an already issued invoice, please contact <a href="mailto:finance@languageburo.com">finance@languageburo.com</a>.
    </div>

    <CompanyDetailsHeader v-if="isDataFetched" :record="record" :isPublicMode="isPublicMode" @showAlert="showAlert" @showError="showError" />

    <!-- Navigation -->
    <div class="row mb-3">
      <!-- Tabs -->
      <div class="col">
        <ul class="nav nav-pills" id="tabNav" v-show="tabsVisible">
          <li class="nav-item small">
            <a class="nav-link active" data-bs-toggle="tab" href="#companyDetails">Company Details</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#contacts">Contacts</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#opportunities">Opportunities</a>
          </li>

          <li v-if="!isPublicMode" class="nav-item small">
            <a class="nav-link" data-bs-toggle="tab" href="#jobs">Jobs</a>
          </li>
        </ul>
      </div>

      <!-- Secondary actions -->
      <div class="col d-flex justify-content-end align-items-center">
        <template v-if="!isPublicMode">
          <!-- Delete Company -->
          <a v-if="isDataFetched && !record.fields['Opps']" @click="openDeleteCompanyModal()" :disabled="isDeleting('deletingCompany')" class="btn btn-sm btn-danger ms-2"><i class="bi bi-trash me-1"></i> Delete Company</a>

          <!-- Copy Company Public Link -->
          <a class="btn btn-sm btn-outline-secondary ms-2" @click="copyToClipboard(record.fields['Company Public Link'])">
            <i class="bi me-1" :class="copied ? 'bi-check' : 'bi-clipboard'"></i> {{ copied ? 'Copied' : 'Copy Company Public Link' }}
          </a>
        </template>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content">
      <!-- Main Tab -->
      <div id="companyDetails" class="tab-pane show active">
        <div v-if="!isFetching('fetchingCompany') && isDataFetched">
          <div class="row">
            <div class="col-9">
              <!-- Section: One -->
              <div class="p-0 bg-white shadow-sm rounded">
                <!-- Section header  -->
                <div class="p-4 pb-2">
                  <div class="row align-items-center">
                    <div class="col">
                      <span class="fs-5 fw-semibold mb-3">Company Details</span>
                    </div>
                    <div class="col text-end">
                      <div v-if="!sectionEditMode.sectionOne">
                        <button class="btn btn-sm btn-primary" @click.prevent="toggleSectionEditMode('sectionOne')"><i class="bi bi-pencil-fill me-1"></i> Edit</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" @click.prevent="saveChangesSection('sectionOne')" :disabled="isUpdating('updatingCompany') || isFetching('fetchingTeam') || isFetching('fetchingCountries') || isFetching('fetchingIndustries')">

                          <div v-if="isUpdating('updatingCompany') || isFetching('fetchingTeam') || isFetching('fetchingCountries') || isFetching('fetchingIndustries')">
                            <div class="spinner-border spinner-border-sm" role="status"></div> Loading...
                          </div>

                          <div v-else>
                            <i class="bi bi-check"></i> Save
                          </div>
                        </button>

                        <button class="btn btn-sm btn-secondary ms-2" @click="cancelSectionEditMode('sectionOne')"><i class="bi bi-x"></i> Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Fields -->
                <div class="p-4 pt-0">
                  <!-- Name -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Name
                    </div>

                    <div class="col-9">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Name'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Name']">
                      </div>
                    </div>
                  </div>

                  <!-- Website -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Website
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <a :href="record.fields['Website']" target="_blank">{{ record.fields['Website'] }}</a>
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Website']">
                        <div class="form-text">Make sure to always insert http:// or https://</div>
                      </div>
                    </div>
                  </div>

                  <!-- Description -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Description
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Description'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="5" v-model="record.fields['Description']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Tags -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Tags
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Tags'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="5" v-model="record.fields['Tags']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- LinkedIn -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      LinkedIn
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <a :href="record.fields['LinkedIn']" target="_blank">{{ record.fields['LinkedIn'] }}</a>
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['LinkedIn']">
                        <div class="form-text">Make sure to always insert http:// or https://</div>
                      </div>
                    </div>
                  </div>

                  <!-- Apollo Employee Count -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Apollo Employee Count
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Apollo Employee Count'] }}
                      </div>

                      <div v-else>
                        <input type="number" class="form-control" v-model="record.fields['Apollo Employee Count']">
                      </div>
                    </div>
                  </div>

                  <!-- Industry -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Industry
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <template v-if="!isPublicMode">
                          <a :href="record.fields['Industry Link']" target="_blank">{{ record.fields['Industry Lookup'] ? record.fields['Industry Lookup'].join(', ') : '' }}</a>
                        </template>

                        <template v-else>
                          {{ record.fields['Industry Lookup'] ? record.fields['Industry Lookup'].join(', ') : '' }}
                        </template>
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingIndustries')" v-model="selectedIndustry" :options="allIndustriesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Country -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Country
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="['badge custom-badge', mixinGetCountryBadgeClass(record.fields['Country Lookup'])]">{{ record.fields['Country Lookup'] ? record.fields['Country Lookup'].join(', ') : '' }}</span>
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingCountries')" v-model="selectedCountry" :options="allCountriesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Preferred Currency -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Preferred Currency
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(record.fields['Preferred Currency'])]">{{ record.fields['Preferred Currency'] }}</span>
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Preferred Currency']" :options="allCurrenciesOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Preferred PM -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Preferred PM
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="record.fields['Preferred PM Lookup'] && record.fields['Preferred PM Lookup'][0] === 'Unassigned' ? '' : 'user border p-1 pe-2 d-inline-block small'">
                          <img :src="record.fields['Preferred PM Photo'] && record.fields['Preferred PM Photo'] ? record.fields['Preferred PM Photo'][0].url : ''" class="rounded-circle user-picture-small">
                          {{ record.fields['Preferred PM Lookup'] ? record.fields['Preferred PM Lookup'].join(', ') : '' }}
                        </span>
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingPMs')" v-model="selectedPreferredPM" :options="allPMsOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Billing Address -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Billing Address
                    </div>

                    <div class="col-5" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Billing Address'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="3" v-model="record.fields['Billing Address']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- VAT -->
                  <div class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      VAT
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['VAT'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['VAT']">
                      </div>
                    </div>
                  </div>

                  <!-- Accounts Payable Email -->
                  <div class="d-flex pt-3 pb-3 align-items-center" :class="{'border-bottom': !isPublicMode}">
                    <div class="col-3 text-muted small">
                      Accounts Payable Email
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Accounts Payable Email'] }}
                      </div>

                      <div v-else>
                        <input type="text" class="form-control" v-model="record.fields['Accounts Payable Email']">
                      </div>
                    </div>
                  </div>

                  <!-- Acquired On -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Acquired On
                    </div>

                    <div class="col-5">
                      {{ mixinFormatDate(record.fields['Acquired On']) }}
                    </div>
                  </div>

                  <!-- Acquired By -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Acquired By
                    </div>

                    <div class="col-5">
                      <div v-if="!sectionEditMode.sectionOne">
                        <span :class="record.fields['Acquired By Lookup'] && record.fields['Acquired By Lookup'][0] === 'Unassigned' ? '' : 'user border p-1 pe-2 d-inline-block small'">
                          <img :src="record.fields['Acquired By Photo'] && record.fields['Acquired By Photo'] ? record.fields['Acquired By Photo'][0].url : ''" class="rounded-circle user-picture-small">
                          {{ record.fields['Acquired By Lookup'] ? record.fields['Acquired By Lookup'].join(', ') : '' }}
                        </span>
                      </div>

                      <div v-else>
                        <v-select v-if="!isFetching('fetchingTeam')" v-model="selectedAcquiredBy" :options="allTeamOptions" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>

                        <div v-else class="d-inline-block">
                          <div class="spinner-border spinner-border-sm text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Late Payer? -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Late Payer?
                    </div>

                    <div class="col-2">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Late Payer?'] }}
                      </div>

                      <div v-else>
                        <v-select v-model="record.fields['Late Payer?']" :options="['Yes', 'No']" placeholder="Select..." :clearable="false">
                          <template v-slot:open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <i class="bi bi-caret-down-fill text-muted small" style="color: #999 !important"></i>
                            </span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>

                  <!-- Notes -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center border-bottom">
                    <div class="col-3 text-muted small">
                      Notes
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['Notes'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="5" v-model="record.fields['Notes']"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- PM Notes -->
                  <div v-if="!isPublicMode" class="d-flex pt-3 pb-3 align-items-center">
                    <div class="col-3 text-muted small">
                      PM Notes
                    </div>

                    <div class="col-9" style="white-space: pre-line;">
                      <div v-if="!sectionEditMode.sectionOne">
                        {{ record.fields['PM Notes'] }}
                      </div>

                      <div v-else>
                        <textarea class="form-control" rows="5" v-model="record.fields['PM Notes']"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isPublicMode" class="col-3 pt-4">
              <div class="section mb-3 border-bottom pb-3" v-if="mixinUserInRoles(['Admin'])">
                <div class="text-muted small mb-2">Total revenue per year</div>
                <ReusableRevenueLineChart v-if="!isFetching('fetchingCompanyOpps')" :chartData="chartData" class="p-2" />
              </div>

              <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">First Order</div>
                <div class="mt-1">
                  <div v-if="record.fields['First Order']">
                    {{ mixinFormatDate(record.fields['First Order']) }}
                    ({{ calculateDaysPassed(record.fields['First Order']) }} days ago)
                  </div>
                  <div v-else>
                    No orders found.
                  </div>
                </div>
              </div>

              <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">Last Order</div>
                <div class="mt-1">
                  <div v-if="record.fields['Last Order']">
                    {{ mixinFormatDate(record.fields['Last Order']) }}
                    ({{ calculateDaysPassed(record.fields['Last Order']) }} days ago)
                  </div>
                  <div v-else>
                    No orders found.
                  </div>
                </div>
              </div>

              <div v-if="mixinUserInRoles(['Admin'])">
                <div v-if="record.fields['First Order']" class="section mb-3 border-bottom pb-3">
                  <div class="text-muted small">Average Order Size</div>
                  <div class="mt-1">€{{ mixinFormatNumber(record.fields['Average Order Size']) }}</div>
                </div>
              </div>

              <div v-if="record.fields['First Order']" class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">Ordered in the last 3 months?</div>
                <div class="mt-1">{{ record.fields['Ordered Within 3 Months?'] }}</div>
              </div>

              <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">Seasonality</div>
                <div class="mt-1" v-html="seasonality"></div>
              </div>

              <!-- <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">@TODO Most frequent language pairs</div>
              </div>

              <div class="section mb-3 border-bottom pb-3">
                <div class="text-muted small">@TODO Content analysis</div>
                <div class="mt-1">Feed AI with files to get a summary of content translated</div>
              </div> -->
            </div>
          </div>
        </div>

        <div v-else>
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Contacts Tab -->
      <CompanyDetailsContactsTab ref="companyDetailsContactsTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Opportunities Tab -->
      <CompanyDetailsOpportunitiesTab ref="companyDetailsOpportunitiesTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />

      <!-- Jobs Tab -->
      <CompanyDetailsJobsTab ref="companyDetailsJobsTab" :recordId="recordId" @showAlert="showAlert" @showError="showError" />
    </div>

    <!-- Delete Company -->
    <DeleteCompanyModal ref="deleteCompanyModal" :recordId="recordId" @showAlert="showAlert" @showError="showError" />
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import CompanyDetailsHeader from '@/components/CompanyDetailsHeader.vue';
import CompanyDetailsContactsTab from '@/components/CompanyDetailsContactsTab.vue';
import CompanyDetailsOpportunitiesTab from '@/components/CompanyDetailsOpportunitiesTab.vue';
import CompanyDetailsJobsTab from '@/components/CompanyDetailsJobsTab.vue';
import DeleteCompanyModal from '@/components/DeleteCompanyModal.vue';
import ReusableRevenueLineChart from '@/components/ReusableRevenueLineChart.vue';

export default {
  name: 'CompanyDetails',
  emits: [
    'showAlert',
    'showError',
  ],
  props: {
    isPublicMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CompanyDetailsHeader,
    CompanyDetailsContactsTab,
    CompanyDetailsOpportunitiesTab,
    CompanyDetailsJobsTab,
    DeleteCompanyModal,
    ReusableRevenueLineChart,
  },

  data() {
    return {
      isDataFetched: false,

      sectionEditMode: {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
      },

      recordId: this.$route.params.id,

      record: { },
      tempRecord: null,

      tableName: 'Companies',
      stateVariable: 'company',
      creatingStateVariable: 'creatingCompany',
      fetchingStateVariable: 'fetchingCompany',
      updatingStateVariable: 'updatingCompany',
      deletingStateVariable: 'deletingCompany',

      tabsVisible: false,
      copied: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTeamOptions',
      'allPMsOptions',
      'allCountriesOptions',
      'allCurrenciesOptions',
      'allIndustriesOptions',

      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
    ]),

    ordersByQuarter() {
      if (!this.record.fields['Order Dates']) {
        return [0, 0, 0, 0]; // Return an array of zeros when there are no orders
      }

      let orderDatesArray = this.record.fields['Order Dates'];
      let quarters = [0, 0, 0, 0]; // Initialize an array to hold the number of orders in each quarter

      orderDatesArray.forEach(dateString => {
        let date = new Date(dateString);
        let quarter = Math.floor(date.getMonth() / 3);
        quarters[quarter]++;
      });

      return quarters;
    },

    seasonality() {
      if (!this.record.fields['Order Dates']) {
        return 'This company has not placed any orders.'; // Return a message when there are no orders
      }

      let quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
      let ordersByQuarter = this.ordersByQuarter;
      let currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

      // Create an array of [quarter, orders] pairs, sort it by the number of orders, and map it back to an array of quarters
      let sortedQuarters = quarters
        .map((quarter, index) => [quarter, ordersByQuarter[index]])
        .sort((a, b) => b[1] - a[1])
        .map(pair => pair[0]);

      // Determine the likelihood of ordering in the current quarter
      let likelihood;
      if (sortedQuarters.indexOf('Q' + currentQuarter) === 0) {
        likelihood = 'likely';
      } else if (sortedQuarters.indexOf('Q' + currentQuarter) === 1) {
        likelihood = 'somewhat likely';
      } else {
        likelihood = 'unlikely';
      }

      return 'Company orders more in ' + sortedQuarters.join(', then ') + '. It\'s <strong>' + likelihood + '</strong> that this company will order in this quarter.';
    },

    averageOrderInterval() {
      return this.calculateAverageOrderInterval(this.record.fields['Order Dates']);
    },

    chartData() {
      const opportunities = this.getProperty('companyOpps');

      // Filter opportunities to only include those with a status that starts with "Order:"
      const orders = opportunities.filter(opp => opp.fields['Status'].startsWith('Order:'));

      const revenueByYear = orders.reduce((acc, order) => {
        const year = new Date(order.fields["Order Start Date"]).getFullYear();
        const revenue = order.fields["9 - Gross Revenue in €"];

        // Check if year or revenue is NaN and log it
        if (isNaN(year) || isNaN(revenue)) {
          console.log('Invalid data:', order);
        }

        acc[year] = (acc[year] || 0) + revenue;
        return acc;
      }, {});

      // Create arrays for the labels and data
      const labels = Object.keys(revenueByYear).sort();
      const data = labels.map(year => revenueByYear[year]);

      return {
        labels: labels,
        datasets: [{
          label: 'Revenue',
          backgroundColor: 'rgba(255, 63, 105, .5)',
          data: data,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: 'rgba(255, 63, 105, 1)',
        }]
      };
    },

    selectedCountry: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Country', optionsArrayName: 'allCountriesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Country', value: value, optionsArrayName: 'allCountriesOptions' });
      }
    },

    selectedPreferredPM: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Preferred PM', optionsArrayName: 'allPMsOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Preferred PM', value: value, optionsArrayName: 'allPMsOptions' });
      }
    },

    selectedAcquiredBy: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Acquired By', optionsArrayName: 'allTeamOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Acquired By', value: value, optionsArrayName: 'allTeamOptions' });
      }
    },

    selectedIndustry: {
      get() {
        return this.mixinGetSelectedField({ fieldName: 'Industry', optionsArrayName: 'allIndustriesOptions' });
      },
      set(value) {
        this.mixinSetSelectedField({ fieldName: 'Industry', value: value, optionsArrayName: 'allIndustriesOptions' });
      }
    },
  },

  watch: { },

  methods: {
    calculateDaysPassed(date) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date);
      const secondDate = new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays;
    },

    calculateAverageOrderInterval(orderDatesArray) {
      // Convert the date strings into Date objects
      let orderDates = orderDatesArray.map(dateString => new Date(dateString));

      // Sort the dates in ascending order
      orderDates.sort((a, b) => a - b);

      // Calculate the differences between consecutive dates
      let dateDifferences = [];
      for (let i = 1; i < orderDates.length; i++) {
        let difference = (orderDates[i] - orderDates[i - 1]) / (1000 * 60 * 60 * 24); // difference in days
        dateDifferences.push(difference);
      }

      // Calculate the average of the differences
      let sum = dateDifferences.reduce((a, b) => a + b, 0);
      let average = sum / dateDifferences.length;

      return average;
    },

    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 3000); // Reset after 3 seconds
      }, err => {
        console.error('Could not copy text: ', err);
      });
    },

    async openDeleteCompanyModal() {
      this.$refs.deleteCompanyModal.openModal();
    },

    toggleSectionEditMode(section) {
      this.sectionEditMode[section] = !this.sectionEditMode[section];

      if (this.sectionEditMode[section]) {
        this.tempRecord = JSON.parse(JSON.stringify(this.record));
      }
    },

    cancelSectionEditMode(section) {
      this.sectionEditMode[section] = false;
      this.record = JSON.parse(JSON.stringify(this.tempRecord));
    },

    saveChangesSection() {
      this.updateRecord();
    },

    createUpdatedFields() {
      return {
        'Name': this.record.fields['Name'],
        'Website': this.record.fields['Website'],
        'LinkedIn': this.record.fields['LinkedIn'],
        'Apollo Employee Count': this.record.fields['Apollo Employee Count'] || null,
        'Country': this.record.fields['Country'],
        'Preferred Currency': this.record.fields['Preferred Currency'].value,
        'Preferred PM': this.record.fields['Preferred PM'],
        'Description': this.record.fields['Description'],
        'Tags': this.record.fields['Tags'],
        'Industry': this.record.fields['Industry'],
        'Billing Address': this.record.fields['Billing Address'],
        'VAT': this.record.fields['VAT'],
        'Accounts Payable Email': this.record.fields['Accounts Payable Email'],
        'Acquired By': this.record.fields['Acquired By'],
        'Late Payer?': this.record.fields['Late Payer?'],
        'Notes': this.record.fields['Notes'],
        'PM Notes': this.record.fields['PM Notes'],
      };
    },

    validateFields() {
      let errors = [];

      if (!this.record.fields['Name']) { errors.push('"Name" is required.'); }

      return errors;
    },

    async fetchRecord() {
      try {
        this.record = await this.$store.dispatch('fetchRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        this.isDataFetched = true;

      } catch (error) {
        this.$emit('showError', error.message);
        console.error(error);
      }
    },

    async updateRecord() {
      const validationErrors = this.validateFields();

      if (validationErrors.length > 0) {
        this.$emit('showAlert', 'danger', validationErrors.join(' '));
        return;
      }

      try {
        await this.$store.dispatch('updateRecord', {
          tableName: this.tableName,
          recordId: this.recordId,
          updatedFields: this.createUpdatedFields(),
          updatingStateVariable: this.updatingStateVariable,
        });

        Object.keys(this.sectionEditMode).forEach((key) => {
          this.sectionEditMode[key] = false;
        });

        this.$emit('showAlert', 'success', 'Successfully updated record');
        await this.fetchRecord();

      } catch (error) {
        this.$emit('showAlert', 'danger', `Error updating record: ${error.message}`);
        console.error(error);
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });

    await this.fetchRecord();

    const name = this.record.fields['Name'];
    document.title = `${name}`;
  },

  created() {
    if (!this.isPublicMode) {
      this.mixinCheckUserAuthentication();
    }
  },
};
</script>